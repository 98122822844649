<template>
  <base-modal-side :is-dimmed="true" @closeModal="actions.closeModal()">
    <template #modalHeader>
      <div class="header">
        <h3>{{ state.title }}</h3>
        <p class="sub-text-s3 text-gray-second">{{ state.description }}</p>
      </div>
    </template>

    <template #modalBody>
      <input-basic
        label="티켓명"
        placeholder="티켓명을 입력해주세요."
        :required="true"
        :default-value="state.planForm.title"
        :max-length="25"
        :caption="`${state.planForm.title.length}/25`"
        :disabled="state.isSoldPlan"
        :error-message="state.titleError"
        @updateData="(value) => actions.updatePlanTitle(value)"
      ></input-basic>

      <div class="plan-description-section">
        <label class="sub-text-s2">
          구성소개 <span class="text-purple-50">*</span>
        </label>
        <p class="text-gray-second sub-text-s3">
          티켓 구매자들에게 제공될 구성 및 혜택을 입력해주세요. (최대 3개까지
          가능)
        </p>

        <div class="input-area">
          <input-basic
            placeholder="구성 및 혜택 내용을 입력해주세요"
            :default-value="state.inputDescriptionTag"
            :max-length="20"
            :caption="`${state.inputDescriptionTag.length}/20`"
            :disabled="state.isSoldPlan"
            :error-message="state.tagsError"
            :is-submit-event="true"
            :is-focus-event="true"
            @updateData="(value) => actions.updateDescriptionTag(value)"
            @submitEvent="(data) => actions.addPlanDescription(data)"
            @focusEvent="actions.focusEvent()"
          ></input-basic>

          <div
            v-if="state.showDescriptionInputGuide"
            class="description-guide sub-text-s3"
          >
            <span class="text-purple-20">입력</span
            ><span>하려면 엔터(Enter) 키를 눌러주세요</span>
          </div>

          <div class="desc-list">
            <badge-hash-tag
              v-for="(tag, index) in state.planForm.descriptions"
              :key="`plan-description-tag-${index}`"
              class="tag-item"
              :text="tag"
              @delete="
                state.isSoldPlan ? '' : actions.updatePlanDescriptions(index)
              "
            ></badge-hash-tag>
          </div>
        </div>
      </div>

      <div class="price-area">
        <label class="sub-text-s2"> 가격 </label>

        <div class="price-inputs">
          <input-money
            class="list-price"
            label="정가"
            :value="state.planForm.prices.KRW.listPrice"
            :disabled="state.isSoldPlan"
            :is-focus-out-event="true"
            placeholder="정가를 입력해주세요."
            @updateData="(value) => actions.updateListPrice(value)"
          ></input-money>
          <input-money
            class="price"
            label="판매가"
            :required="true"
            :value="state.planForm.prices.KRW.price"
            :discount="state.discount"
            :disabled="state.isSoldPlan"
            :is-focus-out-event="true"
            placeholder="판매가를 입력해주세요."
            @updateData="(value) => actions.updatePrice(value)"
          ></input-money>
        </div>
        <p
          v-if="state.priceError"
          class="sub-text-s3 text-red-50 error-message"
        >
          {{ state.priceError }}
        </p>
      </div>

      <hr class="hr-third" />

      <button-accordion @toggleContents="actions.toggleDetailOptionForm()">
        <template #button>
          <button-text
            text="고급 옵션"
            text-size="s2"
            :is-icon="true"
            icon-position="back"
          >
            <template #icon>
              <arrow-icon
                :direction="state.showDetailOptionsForm ? 'up' : 'down'"
              ></arrow-icon>
            </template>
          </button-text>
        </template>
        <template #contents>
          <div class="detail-option">
            <div>
              <label class="sub-text-s2"> 얼리버드 </label>
              <p class="text-gray-second sub-text-s3">
                판매 중인 상품에 얼리버드 태그가 노출됩니다.
              </p>
            </div>
            <input-switch v-model="state.planForm.isEarlyBird"></input-switch>
          </div>

          <div v-if="!state.isLiveSession" class="detail-option">
            <div>
              <label class="sub-text-s2"> 시청 기한 (일) </label>
              <p class="text-gray-second sub-text-s3">
                구매자들이 구매일로부터 콘텐츠를 시청할 수 있는 기간을
                설정합니다. 미설정 시 90일 동안 시청할 수 있습니다.
              </p>
            </div>

            <input-basic
              type="number"
              placeholder=""
              :default-value="state.planForm.contentExpiredDay"
              :disabled="state.isSoldPlan"
              :error-message="state.durationError"
              @updateData="(value) => actions.updatePlanDuration(value)"
            ></input-basic>
          </div>

          <div class="detail-option">
            <div>
              <label class="sub-text-s2">구매 인원 제한</label>
              <p class="text-gray-second sub-text-s3">
                인원 제한 설정 시 해당 수량만큼만 판매 가능합니다.
              </p>
            </div>

            <input-switch v-model="state.isLimitCount"></input-switch>
          </div>

          <input-basic
            type="number"
            placeholder="구매 제한 인원 수 입력해 주세요."
            :disabled="state.isSoldPlan || !state.isLimitCount"
            :default-value="state.planForm.limitCount"
            :error-message="state.limitCountError"
            @updateData="(value) => actions.updatePlanLimitCount(value)"
          ></input-basic>
        </template>
      </button-accordion>

      <hr class="hr-third" />

      <div class="contents">
        <label class="sub-text-s2"> 포함될 콘텐츠 </label>
        <p class="text-gray-second sub-text-s3">
          구매자들이 시청할 수 있는 콘텐츠를 선택해주세요. 티켓별로 다르게
          설정할 수 있습니다.
        </p>
      </div>

      <ul class="selectable-sessions">
        <li
          v-for="(session, index) in state.sessions"
          :key="`selectable-session-${session.resourceId}`"
        >
          <selectable-session-item
            :club="club"
            :session="session"
            :number="index + 1"
            :plan="plan"
            @connectSession="actions.connectSession(session)"
            @unConnectSession="actions.unConnectSession(session)"
          ></selectable-session-item>
        </li>
      </ul>
    </template>

    <template #modalFooter>
      <div class="button-wrapper">
        <button-basic
          class="cancel-btn"
          color="#0d0d0d"
          bg-color="#ECF1F4"
          text="취소"
          @action="actions.closeModal()"
        ></button-basic>

        <button-basic
          class="save-btn"
          :text="plan ? '수정' : '등록'"
          @action="plan ? actions.editPlan() : actions.createPlan()"
        ></button-basic>
      </div>
    </template>
  </base-modal-side>
</template>

<script>
import { computed, onBeforeMount, reactive, ref, watch } from "vue";
import InputBasic from "../../../../components/console/inputs/InputBasic";
import InputSwitch from "../../../../components/console/inputs/InputSwitch";
import SelectableSessionItem from "../SelectableSessionItem/SelectableSessionItem";
import BadgeHashTag from "../../../../components/console/badges/BadgeHashTag";
import { useStore } from "vuex";
import swal from "../../../../helper/swal";
import InputMoney from "../../../../components/console/inputs/InputMoney";
import moment from "moment-timezone";
import BaseModalSide from "../../../../components/common/BaseModalSide.vue";
import { isSoldPlan, planListPrice, planPrice } from "@/helper/plan";
import ButtonAccordion from "@/components/console/buttons/ButtonAccordion.vue";
import ButtonText from "@/components/console/buttons/ButtonText.vue";
import ArrowIcon from "@/components/console/icons/ArrowIcon.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default {
  name: "PlanModal",
  components: {
    ButtonBasic,
    ArrowIcon,
    ButtonText,
    ButtonAccordion,
    BaseModalSide,
    InputMoney,
    BadgeHashTag,
    SelectableSessionItem,
    InputSwitch,
    InputBasic,
  },
  props: {
    club: {
      type: Object,
      required: true,
    },
    plan: {
      type: Object,
      required: false,
    },
  },
  emits: ["openPlanListModal", "saveComplete", "hideModal"],
  setup(props, { emit }) {
    const modalContainer = ref();

    const store = useStore();

    const state = reactive({
      title: computed(() => {
        return props.plan ? "티켓 수정" : "티켓 추가";
      }),
      description: computed(() => {
        return props.plan
          ? "여러 가지의 티켓을 만들어 각각 옵션을 다르게 설정할 수 있습니다."
          : "판매가를 설정하고 티켓 구매자들이 시청할 수 있는 콘텐츠를 선택할 수 있습니다.";
      }),
      planForm: {
        title: "",
        prices: {
          KRW: {
            currency: "KRW",
            listPrice: props.plan ? planListPrice(props.plan) : 0,
            price: props.plan ? planPrice(props.plan) : 0,
          },
        },
        contentExpiredDay: 90,
        descriptions: [],
        isPrimary: false,
        isEarlyBird: false,
        limitCount: null,
        contentResourceIds: [],
        meetingResourceIds: [],
      },
      sessions: computed(() => {
        return store.getters["newClubs/newClubSessions"];
      }),
      isLimitCount: false,
      isSelectSession: false,
      isDuration: true,
      showDetailOptionsForm: false,
      discount: computed(() => {
        let listPrice = state.planForm.prices.KRW.listPrice;
        let price = state.planForm.prices.KRW.price;

        if (!!listPrice && !!price && Number(listPrice) > Number(price)) {
          let discount = ((listPrice - price) / listPrice) * 100;
          return Math.round(discount) + "%";
        }

        return "";
      }),
      isLiveSession: computed(() => {
        return props.club.contentType === "meeting";
      }),
      titleError: "",
      priceError: "",
      tagsError: "",
      limitCountError: "",
      durationError: "",
      inputDescriptionTag: "",
      completeBeforeMount: false,
      showDescriptionInputGuide: false,
      isSoldPlan: computed(() => {
        return props.plan ? isSoldPlan(props.plan) : false;
      }),
      isSaving: false,
    });

    watch(
      () => state.tagsError,
      (value) => {
        if (value) {
          state.showDescriptionInputGuide = false;
        } else {
          state.showDescriptionInputGuide = true;
        }
      }
    );

    onBeforeMount(() => {
      if (props.plan) {
        state.planForm.title = props.plan.title;
        state.planForm.descriptions = [...props.plan.descriptions];
        state.planForm.prices = {
          ...state.planForm.prices,
          ...props.plan.prices,
        };
        state.planForm.isEarlyBird = props.plan.isEarlyBird;
        state.planForm.limitCount = props.plan.limitCount;
        if (props.plan.contentExpiredDay) {
          state.isDuration = true;
          state.planForm.contentExpiredDay = props.plan.contentExpiredDay;
        }

        state.isLimitCount = props.plan.limitCount > 0;

        if (props.plan.contents.length > 0) {
          props.plan.contents.forEach((content) => {
            state.planForm.contentResourceIds.push(content.resourceId);
          });
        }

        if (props.plan.meetings.length > 0) {
          props.plan.meetings.forEach((meeting) => {
            state.planForm.meetingResourceIds.push(meeting.resourceId);
          });
        }

        state.showDetailOptionsForm = true;
      }
      // default form setting
      else {
        state.planForm.isEarlyBird = false;
        state.isLimitCount = false;

        state.planForm.prices.KRW.price = props.club?.theme?.holderPriceKRW;
        state.isDuration = true;
      }

      state.completeBeforeMount = true;
    });

    watch(
      () => [state.isLimitCount],
      (changes) => {
        if (changes[0] && !state.planForm.limitCount) {
          state.planForm.limitCount = 100;
        }
        if (!changes[0]) {
          state.planForm.limitCount = null;
        }
      }
    );

    const actions = {
      closeModal: () => {
        if (props.plan) {
          emit("openPlanListModal");
        } else {
          emit("hideModal");
        }
      },
      addPlanDescription: (data) => {
        if (state.planForm.descriptions.length < 3) {
          state.planForm.descriptions.push(data);
        } else {
          state.tagsError = "최대 3개 까지만 등록 가능 합니다.";
          setTimeout(() => {
            if (state.tagsError) {
              state.tagsError = "";
            }
          }, 2000);
        }
        state.inputDescriptionTag = "";
      },
      updatePlanDescriptions: (index) => {
        state.planForm.descriptions.splice(index, 1);
      },
      createPlan: () => {
        if (!state.isSaving) {
          state.isSaving = true;

          let listPrice = state.planForm.prices.KRW.listPrice;
          let price = state.planForm.prices.KRW.price;

          if (!state.planForm.title) {
            state.titleError = "티켓명은 필수 입니다.";
            state.isSaving = false;
            return;
          }

          if (state.planForm.descriptions.length === 0) {
            state.tagsError = "구성소개는 필수입니다.";
            state.isSaving = false;
            return;
          }

          if (!price) {
            state.priceError = "판매가는 필수 입니다.";
            state.isSaving = false;
            return;
          }

          if (price < 5000) {
            state.priceError = "판매가는 5,000원 이상부터 가능합니다.";
            state.isSaving = false;
            return;
          }

          if (!!listPrice && !!price && Number(listPrice) <= Number(price)) {
            state.priceError = "판매가는 정가보다 작아야합니다.";
            state.isSaving = false;
            return "";
          }

          if (
            state.isLimitCount &&
            (state.planForm.limitCount <= 0 || !state.planForm.limitCount)
          ) {
            state.limitCountError = " 1명 이상 입력하세요.";
            state.isSaving = false;
            return;
          }

          if (state.isDuration && !state.planForm.contentExpiredDay) {
            state.durationError = "시청기한은 1일 이상이어야 합니다.";
            state.isSaving = false;
            return;
          }

          if (!state.isDuration) {
            state.planForm.contentExpiredDay = null;
          }

          if (!state.isLimitCount) {
            state.planForm.limitCount = null;
          }

          const payload = {
            ...state.planForm,
            ...{
              prices: JSON.stringify(state.planForm.prices),
              startAt: moment().toJSON(),
            },
          };

          store
            .dispatch("newClubs/postPlan", {
              clubResourceId: props.club.resourceId,
              data: payload,
            })
            .then(() => {
              state.titleError = "";
              state.priceError = "";

              swal.successToast("티켓이 생성되었습니다.");
              emit("saveComplete");
              state.isSaving = false;
            });
        }
      },
      editPlan: () => {
        if (!state.isSaving) {
          state.isSaving = true;

          let listPrice = state.planForm.prices.KRW.listPrice;
          let price = state.planForm.prices.KRW.price;

          if (!state.planForm.title) {
            state.titleError = "티켓명은 필수입니다.";
            state.isSaving = false;
            return;
          }

          if (state.planForm.descriptions.length === 0) {
            state.tagsError = "구성소개는 필수입니다.";
            state.isSaving = false;
            return;
          }

          if (!price) {
            state.priceError = "판매가는 필수입니다.";
            state.isSaving = false;
            return;
          }

          if (price < 5000) {
            state.priceError = "판매가는 5,000원 이상부터 가능합니다.";
            state.isSaving = false;
            return;
          }

          if (!!listPrice && !!price && Number(listPrice) <= Number(price)) {
            state.priceError = "판매가는 정가보다 작아야합니다.";
            state.isSaving = false;
            return "";
          }

          if (
            state.isLimitCount &&
            (state.planForm.limitCount <= props.plan.paymentOrderCount ||
              !state.planForm.limitCount)
          ) {
            state.limitCountError = "1명 이상 입력하세요.";
            state.isSaving = false;
            return;
          }

          if (state.isDuration && !state.planForm.contentExpiredDay) {
            state.durationError = "시청기한은 1일 이상이어야 합니다.";
            state.isSaving = false;
            return;
          }

          if (!state.isDuration) {
            state.planForm.contentExpiredDay = null;
          }

          if (!state.isLimitCount) {
            state.planForm.limitCount = null;
          }

          const payload = {
            ...state.planForm,
            ...{
              prices: JSON.stringify(state.planForm.prices),
            },
          };

          store
            .dispatch("newClubs/putPlan", {
              clubResourceId: props.club.resourceId,
              planResourceId: props.plan.resourceId,
              data: payload,
            })
            .then(() => {
              swal.successToast("수정이 완료되었습니다.");
              state.isSaving = false;
              emit("saveComplete");
            });
        }
      },
      updateListPrice: (value) => {
        state.planForm.prices.KRW.listPrice = value;
      },
      updatePrice: (value) => {
        state.planForm.prices.KRW.price = value;
      },

      updatePlanTitle: (value) => {
        state.planForm.title = value;
      },
      connectSession: (session) => {
        if (session.content) {
          state.planForm.contentResourceIds.push(session.content.resourceId);
        }

        if (session.meeting) {
          state.planForm.meetingResourceIds.push(session.meeting.resourceId);
        }
      },
      unConnectSession: (session) => {
        if (session.content) {
          const index = state.planForm.contentResourceIds.findIndex((item) => {
            return item === session.content.resourceId;
          });
          state.planForm.contentResourceIds.splice(index, 1);
        }

        if (session.meeting) {
          const index = state.planForm.meetingResourceIds.findIndex((item) => {
            return item === session.meeting.resourceId;
          });
          state.planForm.meetingResourceIds.splice(index, 1);
        }
      },
      updatePlanLimitCount: (value) => {
        state.planForm.limitCount = value;
        state.limitCountError = "";
      },
      updatePlanDuration: (value) => {
        state.planForm.contentExpiredDay = value;
      },
      toggleDetailOptionForm: () => {
        state.showDetailOptionsForm = !state.showDetailOptionsForm;
      },
      updateDescriptionTag: (value) => {
        state.inputDescriptionTag = value;
      },
      focusEvent: () => {
        state.showDescriptionInputGuide = true;
      },
    };

    return {
      state,
      actions,
      modalContainer,
    };
  },
};
</script>

<style src="./style.css" scoped></style>
