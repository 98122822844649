<template>
  <div class="ticket-info">
    <div
      class="text-area"
      :class="{ 'no-desc': plan.descriptions.length === 0 }"
    >
      <h3>{{ plan.title }}</h3>
      <ul>
        <li
          v-for="(description, index) in plan.descriptions"
          :key="`plan-description-item-${index}`"
        >
          <i class="fa-solid fa-check"></i>
          <span class="b-text-3 text-default">
            {{ description }}
          </span>
        </li>
      </ul>
    </div>

    <div v-if="state.listPrice" class="price-area-benefit">
      <div>
        <p class="list-price-txt sub-text-s3 text-gray-second">정상가</p>
        <p class="list-price text-gray-second">
          {{ state.listPrice }}
        </p>
      </div>
      <hr class="hr-second" />
      <div>
        <p class="price-txt sub-text-s3 text-default">혜택가</p>
        <div class="price">
          <h3 class="discount">{{ state.discount }}</h3>
          <h3>{{ state.price }}</h3>
        </div>
      </div>
    </div>

    <div
      v-if="!state.listPrice || state.listPrice === state.price"
      class="price-area"
    >
      <div class="info-wrapper">
        <p class="price-txt sub-text-s3 text-default">판매가</p>
        <h2>{{ state.price }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import {
  planListPrice,
  planPrice,
  planDiscount,
  planSoldOut,
} from "@/helper/plan";

export default {
  name: "SelectedPlan",
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      listPrice: computed(() => {
        return planListPrice(props.plan);
      }),
      price: computed(() => {
        return planPrice(props.plan);
      }),
      discount: computed(() => {
        return planDiscount(props.plan);
      }),
      soldOut: computed(() => {
        return planSoldOut(props.plan);
      }),
    });

    return { state };
  },
};
</script>

<style src="./style.css" scoped></style>
