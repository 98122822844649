<template>
  <div>
    <div ref="toolbar">
      <button class="ql-header" value="1" type="button">H1</button>
      <button class="ql-header" value="2" type="button">H2</button>
      <button class="ql-header" value="3" type="button">B1</button>
      <button class="ql-header" value="4" type="button">B2</button>

      <span class="divider">
        <text-divider height="24px" color="#E6EAEF" margin="0 0"></text-divider>
      </span>

      <button class="ql-bold"></button>
      <button class="ql-underline">underline</button>
      <button class="ql-strike"></button>

      <span class="divider">
        <text-divider height="24px" color="#E6EAEF" margin="0 0"></text-divider>
      </span>

      <button class="ql-align" value=""></button>
      <button class="ql-align" value="center"></button>
      <button class="ql-align" value="right"></button>

      <span class="divider">
        <text-divider height="24px" color="#E6EAEF" margin="0 0"></text-divider>
      </span>

      <button type="button" class="ql-list" value="bullet"></button>
      <button type="button" class="ql-list" value="ordered"></button>

      <span class="divider">
        <text-divider height="24px" color="#E6EAEF" margin="0 0"></text-divider>
      </span>

      <button class="ql-link" @click="actions.openLinkForm">
        <link-icon fill-color="#0D0D10"></link-icon>
      </button>
    </div>

    <div class="editor-wrapper" :class="{ active: state.activeEditor }">
      <div
        ref="quillEditor"
        class="cms-editor none-cursor-state"
        :style="state.quillStyleObj"
      ></div>

      <cms-editor-toolbar
        class="cms-editor-toolbar"
        :club-resource-id="clubResourceId"
        @openImageSelector="actions.openFileStack()"
        @embedVideo="(videoUrl) => actions.embedVideo(videoUrl)"
        @embedHlVideo="(hlVideoUrl) => actions.embedHlVideo(hlVideoUrl)"
        @insertHrTag="actions.insertHrTag()"
      ></cms-editor-toolbar>
    </div>
  </div>
</template>

<script>
import Quill from "quill";

import "quill/dist/quill.bubble.css";
import { onMounted, reactive, ref, onBeforeUnmount, computed } from "vue";
import CmsEditorToolbar from "../CmsEditorToolbar/CmsEditorToolbar";
import FileStackService from "../../../../services/FileStackService";
import ApiService from "@/api";
import TextDivider from "../../../../components/console/dividers/TextDivider";
import LinkIcon from "../../../../components/console/icons/LinkIcon";

export default {
  name: "CmsEditor",
  components: { LinkIcon, TextDivider, CmsEditorToolbar },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    height: {
      type: Number,
      required: false,
    },
    clubResourceId: {
      type: String,
      default: "",
    },
  },
  emits: ["updateContent"],
  setup(props, { emit }) {
    const quillEditor = ref();
    const toolbar = ref();

    const state = reactive({
      quillStyleObj: {
        width: "100%",
        height: props.height ? `${props.height}px` : "auto",
        minHeight: "650px",
      },
      form: {
        content: props.content ? props.content : "",
        attachments: computed(() => {
          return state.attachments.reduce((result, currentArray) => {
            result.push = currentArray.resourceId;
            return result;
          }, []);
        }),
      },
      lastSelection: 0,
      attachments: [],
      activeEditor: false,
    });

    let quillInstance;

    onMounted(async () => {
      // 아이콘 커스텀
      let icons = Quill.import("ui/icons");
      icons["header"]["1"] = `<img src="/assets/images/svg/ic_header1.svg" />`;
      icons["header"]["2"] = `<img src="/assets/images/svg/ic_header2.svg" />`;
      icons["header"]["3"] = `<img src="/assets/images/svg/ic_header3.svg" />`;
      icons["header"]["4"] = `<img src="/assets/images/svg/ic_header4.svg" />`;
      icons["bold"] = `<img src="/assets/images/svg/ic_bold.svg"/>`;
      icons["underline"] = `<img src="/assets/images/svg/ic_underline.svg" />`;
      icons["strike"] = `<img src="/assets/images/svg/ic_strike.svg" />`;
      icons["align"][""] = `<img src="/assets/images/svg/ic_align_left.svg" />`;
      icons["align"][
        "center"
      ] = `<img src="/assets/images/svg/ic_align_center.svg" />`;
      icons["align"][
        "right"
      ] = `<img src="/assets/images/svg/ic_align_right.svg" />`;
      icons["list"][
        "bullet"
      ] = `<img src="/assets/images/svg/ic_list_bullet.svg" />`;
      icons["list"][
        "ordered"
      ] = `<img src="/assets/images/svg/ic_list_ordered.svg" />`;

      // icons["link"] = `<img src="/assets/images/svg/ic_link.svg" />`;

      let EmbedBlock = Quill.import("blots/block/embed");
      class Hr extends EmbedBlock {}
      Hr.blotName = "hr"; //now you can use .ql-hr classname in your toolbar
      Hr.tagName = "hr";
      Quill.register({
        "formats/hr": Hr,
      });

      // 붙여넣기 할때, 맨 위로 튀는 현상 fix
      // const Clipboard = Quill.import("modules/clipboard");

      // note 글자 서식 복사 제한하는 로직
      // class PlainTextClipboard extends Clipboard {
      //   onPaste(e) {
      //     if (e.defaultPrevented || !this.quill.isEnabled()) return;
      //     let range = this.quill.getSelection();
      //     let delta = new Delta().retain(range.index);
      //
      //     if (
      //       e &&
      //       e.clipboardData &&
      //       e.clipboardData.types &&
      //       e.clipboardData.getData
      //     ) {
      //       let text = (e.originalEvent || e).clipboardData.getData(
      //         "text/plain"
      //       );
      //       let cleanedText = this.convert(text);
      //
      //       // Stop the data from actually being pasted
      //       e.stopPropagation();
      //       e.preventDefault();
      //
      //       // Process cleaned text
      //       delta = delta.concat(cleanedText).delete(range.length);
      //       this.quill.updateContents(delta, Quill.sources.USER);
      //       // range.length contributes to delta.length()
      //       this.quill.setSelection(
      //         delta.length() - range.length,
      //         Quill.sources.SILENT
      //       );
      //
      //       return false;
      //     }
      //   }
      // }
      //
      // Quill.register("modules/clipboard", PlainTextClipboard);

      let options = {
        compatibilityMode: false,
        modules: {
          toolbar: {
            container: toolbar.value,
          },
        },
        debug: false, // 'error', 'warn', 'log', or 'info' , false
        placeholder: props.placeholder,
        theme: "bubble", // bubble or snow
        bounds: quillEditor.value,
        scrollingContainer: "#cms-container",
      };

      quillInstance = await new Quill(quillEditor.value, options);

      // //note 붙여 넣기 할 때, img inline-style width:100%으로 수정하는 코드
      // quillInstance.clipboard.addMatcher("img", function (node, delta) {
      //   return delta.compose(
      //     new Delta().retain(delta.length(), { width: "100%" })
      //   );
      // });

      // note 글자 서식 복사 제한하는 로직
      quillInstance.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        let ops = [];
        delta.ops.forEach((op) => {
          if (op.insert && typeof op.insert === "string") {
            ops.push({
              insert: op.insert,
            });
          }
        });
        delta.ops = ops;
        return delta;
      });

      if (props.content) {
        // todo 에디터에 데이터 바인딩시, delta format으로 변경하는 로직 수정
        // let convertedDelta = quillInstance.clipboard.convert(props.content);
        // quillInstance.setContents(convertedDelta.ops);
        let convertedDelta = htmlToDelta(props.content);
        quillInstance.setContents(convertedDelta, "api");
      }

      quillInstance.on("text-change", update);
      quillInstance.on("selection-change", setLastSelection);

      quillEditor.value.addEventListener("click", () => {
        if (!quillInstance.hasFocus()) {
          const contentLength = quillInstance.getLength();
          quillInstance.setSelection(contentLength + 1, Quill.sources.USER);
        }
      });
    });

    onBeforeUnmount(() => {
      quillInstance.off("text-change", update);
      quillInstance.off("selection-change", setLastSelection);
    });

    const htmlToDelta = (htmlString) => {
      const div = document.createElement("div");
      div.setAttribute("id", "htmlToDelta");
      div.innerHTML = `<div id="quillEditor" style="display:none">${htmlString}</div>`;
      document.body.appendChild(div);
      const quill = new Quill("#quillEditor", {
        theme: "snow",
      });
      const delta = quill.getContents();
      document.getElementById("htmlToDelta").remove();
      return delta;
    };

    const update = (delta) => {
      if (delta) {
        state.form.content = quillInstance.root.innerHTML;
        emit("updateContent", state.form);
      }
    };

    const setLastSelection = (range, oldRange) => {
      if (range) {
        state.lastSelection = range.index;
        quillEditor.value.classList.remove("none-cursor-state");
        state.activeEditor = true;
      } else {
        state.lastSelection = oldRange.index;
        quillEditor.value.classList.add("none-cursor-state");
        state.activeEditor = false;
      }
    };

    const actions = {
      test: () => {
        console.log("test");
        // quillInstance.insertText(state.lastSelection, "\n");
        // quillInstance.insertEmbed(state.lastSelection + 1, "hr", "null");
        // quillInstance.setSelection(
        //   state.lastSelection + 2,
        //   Quill.sources.SILENT
        // );
      },
      openFileStack: () => {
        let rangeDefault = quillInstance.getSelection();

        quillInstance.setSelection(rangeDefault.index);
        quillInstance.insertText(rangeDefault.index, "\n");

        const fileStack = new FileStackService();
        fileStack.options.transformations.crop = true;

        fileStack.options.onFileUploadFinished = (fileMetaData) => {
          const formData = new FormData();
          formData.append("file", fileMetaData.url);

          ApiService.postAttachment(formData).then((res) => {
            if (res.data.success) {
              state.attachments.push(res.data.data);

              quillInstance.insertEmbed(
                state.lastSelection,
                "image",
                res.data.data.url
              );
              quillInstance.insertText(rangeDefault.index + 1, "\n");
              quillInstance.setSelection(rangeDefault.index + 2);
            }
          });
        };

        fileStack.open(fileStack.options);
      },
      embedVideo: (videoUrl) => {
        quillInstance.insertEmbed(state.lastSelection, "video", videoUrl);
      },
      embedHlVideo: (hlVideoUrl) => {
        quillInstance.insertEmbed(state.lastSelection, "video", hlVideoUrl);
      },
      insertHrTag: () => {
        let range = quillInstance.getSelection();

        quillInstance.insertText(range.index, "\n");
        quillInstance.insertEmbed(range.index + 1, "hr", "null");
        quillInstance.setSelection(range.index + 2, Quill.sources.SILENT);
      },

      openLinkForm: (e) => {
        e.stopPropagation();
      },
    };

    return {
      toolbar,
      quillEditor,
      state,
      actions,
    };
  },
};
</script>

<style src="./style.css" scoped></style>
