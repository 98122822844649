<template>
  <div>
    <p
      v-if="!state.isEditMode"
      class="prev-input-text"
      :class="{ active: !disabled }"
      :style="textStyle"
      @click="!disabled ? actions.toggleEditMode() : ''"
    >
      {{ defaultValue ? defaultValue : placeholder }}
    </p>
    <input
      v-if="state.isEditMode"
      ref="inputElement"
      class="prev-input"
      type="text"
      :disabled="disabled"
      :placeholder="placeholder"
      :style="textStyle"
      :value="defaultValue"
      @input="actions.updateData($event)"
      @blur="actions.toggleEditMode()"
      @keypress.enter="actions.enterEvent()"
    />
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from "vue";

export default {
  name: "InlineInput",
  props: {
    defaultValue: {
      type: String,
      required: false,
    },
    textStyle: {
      type: Object,
      default: () => {
        return {
          fontSize: "16px",
          color: "#FFFFFF",
          lineHeight: "21px",
          fontWeight: 400,
          height: "21px",
        };
      },
    },
    placeholder: {
      type: String,
      default: "",
    },
    maxLength: {
      type: Number,
      required: false,
    },
    defaultFocusing: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updateData", "enterEvent", "saveData"],
  setup(props, { emit }) {
    const inputElement = ref();

    const state = reactive({
      isEditMode: false,
    });

    onMounted(() => {
      if (props.defaultFocusing) {
        state.isEditMode = true;
        setTimeout(() => {
          inputElement.value.focus();
        }, 50);
      }
    });

    watch(
      () => props.defaultValue,
      (newVal) => {
        if (!newVal) {
          state.isEditMode = true;
        }
      }
    );

    const actions = {
      updateData: (e) => {
        emit("updateData", e.target.value);
      },
      toggleEditMode: () => {
        if (state.isEditMode && !props.defaultValue) {
          state.isEditMode = true;
        } else {
          state.isEditMode = !state.isEditMode;
          if (state.isEditMode) {
            setTimeout(() => {
              inputElement.value.focus();
            }, 50);
          }
        }
        emit("saveData");
      },
      enterEvent: () => {
        emit("enterEvent");
      },
    };

    return { state, actions, inputElement };
  },
};
</script>

<style src="./style.css" scoped></style>
