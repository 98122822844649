<template>
  <base-modal-side :is-dimmed="true" @closeModal="actions.closeModal()">
    <template #modalHeader>
      <div class="header">
        <h3>라이브 일정</h3>
        <p class="sub-text-s2 text-gray-second">
          라이브를 진행할 날짜와 시간을 선택해주세요!
        </p>
      </div>
    </template>

    <template #modalBody>
      <div>
        <div class="start-date">
          <label>
            <span class="sub-text-s2">시작일</span>
            <span class="text-purple-50">*</span>
          </label>
          <input-date-picker
            :date="state.liveDate"
            format="YYYY-MM-DD"
            :enable-time="false"
            @updateDate="(value) => actions.setLiveDate(value)"
          ></input-date-picker>
        </div>

        <div class="time-area">
          <label>라이브 시간</label>
          <div class="picker-wrapper">
            <input-date-picker
              :date="state.liveStartTime"
              format="HH:mm"
              :no-calendar="true"
              @updateDate="(value) => actions.setLiveStartAt(value)"
            ></input-date-picker>

            <input-date-picker
              :date="state.liveFinishTime"
              format="HH:mm"
              :no-calendar="true"
              @updateDate="(value) => actions.setLiveFinishAt(value)"
            ></input-date-picker>
          </div>
        </div>

        <div class="btn-wrapper">
          <button-basic
            class="cancel"
            text="취소"
            text-size="s3"
            padding="8px 14px"
            bg-color="#ECF1F4"
            color="#0d0d0d"
            @action="actions.closeModal()"
          ></button-basic>

          <button-basic
            class="save"
            :text="meeting ? '수정' : '등록'"
            text-size="s3"
            padding="8px 14px"
            :disabled="!state.activeSaveModal"
            @action="state.activeSaveModal ? actions.registerLiveSession() : ''"
          ></button-basic>
        </div>
      </div>
    </template>
  </base-modal-side>
</template>

<script>
import { reactive, computed, onBeforeMount } from "vue";
import moment from "moment-timezone";
import { useStore } from "vuex";
import InputDatePicker from "../../../../components/console/inputs/InputDatePicker";
import BaseModalSide from "../../../../components/common/BaseModalSide.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default {
  name: "SessionLiveModal",
  components: { ButtonBasic, BaseModalSide, InputDatePicker },
  props: {
    club: {
      type: Object,
      required: true,
    },
    meeting: {
      type: Object,
      required: false,
    },
  },
  emits: ["hideModal", "saveComplete"],
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      liveDate: "",
      liveStartTime: "",
      liveFinishTime: "",
      activeSaveModal: computed(() => {
        return (
          state.liveStartTime &&
          state.liveFinishTime &&
          moment(state.liveFinishTime).diff(state.liveStartTime) > 0 &&
          moment(state.liveStartTime) > moment()
        );
      }),
      liveForm: {
        title: props.meeting ? props.meeting.title : props.club.title,
        scheduledAt: computed(() => {
          return moment(state.liveStartTime).toJSON();
        }),
        scheduledFinishAt: computed(() => {
          return moment(state.liveFinishTime).toJSON();
        }),
      },
      errorMessage: "",
      upcomingMeetingResourceId: computed(() => {
        return store.getters["meetings/upcomingMeeting"].resourceId;
      }),
    });

    onBeforeMount(() => {
      if (props.meeting) {
        state.liveDate = moment(props.meeting.scheduledAt);
        state.liveStartTime = moment(props.meeting.scheduledAt);
        state.liveFinishTime = moment(props.meeting.scheduledFinishAt);
      } else {
        state.liveDate = moment();
        state.liveStartTime = moment().add(1, "hours");
        state.liveFinishTime = moment().add(2, "hours");
      }
    });

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      setLiveTime: (value, formatName) => {
        if (formatName === "startAt") {
          state.liveStartTime = value;
        }
        if (formatName === "finishAt") {
          state.liveFinishTime = value;
        }
        if (formatName === "date") {
          state.liveDate = value;
        }
      },
      setLiveDate: (date) => {
        state.liveDate = moment(date);
        let scheduledDate = state.liveDate.format("YYYY-MM-DD");
        let startTime = moment(state.liveStartTime).format("HH:mm");
        let finishTime = moment(state.liveFinishTime).format("HH:mm");
        state.liveStartTime = moment(`${scheduledDate} ${startTime}`);
        state.liveFinishTime = moment(`${scheduledDate} ${finishTime}`);
      },
      setLiveStartAt: (date) => {
        let scheduledDate = state.liveDate.format("YYYY-MM-DD");
        let startTime = moment(date).format("HH:mm");
        let dateTimeResult = `${scheduledDate} ${startTime}`;
        state.liveStartTime = moment(dateTimeResult);
      },
      setLiveFinishAt: (date) => {
        let scheduledDate = state.liveDate.format("YYYY-MM-DD");
        let finishTime = moment(date).format("HH:mm");
        let dateTimeResult = `${scheduledDate} ${finishTime}`;
        state.liveFinishTime = moment(dateTimeResult);
      },
      registerLiveSession: () => {
        if (state.liveForm.scheduledAt < state.liveForm.scheduledFinishAt) {
          state.errorMessage = "시작시간이 설정시간보다 빠를 수 없습니다.";
        }

        let payload = {
          ...state.liveForm,
        };

        if (props.meeting) {
          store
            .dispatch("newClubs/putClubSessionMeeting", {
              clubResourceId: props.club.resourceId,
              meetingResourceId: props.meeting.resourceId,
              data: payload,
            })
            .then(() => {
              emit("saveComplete");
            });
        } else {
          store
            .dispatch("newClubs/postClubSessionLive", {
              clubResourceId: props.club.resourceId,
              data: payload,
            })
            .then(() => {
              emit("saveComplete");
            });
        }
      },
    };

    return { state, actions, moment };
  },
};
</script>

<style src="./style.css" scoped></style>
