<template>
  <div>
    <div ref="toolbar">
      <button class="ql-header" value="4" type="button">B2</button>

      <span class="divider">
        <text-divider height="24px" color="#E6EAEF" margin="0 0"></text-divider>
      </span>

      <button class="ql-bold"></button>
      <button class="ql-underline">underline</button>
      <button class="ql-strike"></button>

      <span class="divider">
        <text-divider height="24px" color="#E6EAEF" margin="0 0"></text-divider>
      </span>

      <button type="button" class="ql-list" value="bullet"></button>
      <button type="button" class="ql-list" value="ordered"></button>

      <span class="divider">
        <text-divider height="24px" color="#E6EAEF" margin="0 0"></text-divider>
      </span>

      <button class="ql-link" @click="actions.openLinkForm"></button>
    </div>

    <div
      ref="quillEditor"
      class="faq-editor"
      :style="state.quillStyleObj"
    ></div>
  </div>
</template>

<script>
import Quill from "quill";
// import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { onMounted, reactive, ref, onBeforeUnmount } from "vue";
import TextDivider from "../../../../components/console/dividers/TextDivider";

export default {
  name: "FaqEditor",
  components: { TextDivider },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    height: {
      type: Number,
      required: false,
    },
  },
  emits: ["updateContent"],
  setup(props, { emit }) {
    const quillEditor = ref();

    const toolbar = ref();

    const state = reactive({
      quillStyleObj: {
        height: props.height ? `${props.height}px` : "auto",
        minHeight: "400px",
      },
      form: {
        content: props.content ? props.content : "",
      },
    });

    let quillInstance;

    onMounted(async () => {
      let icons = Quill.import("ui/icons");
      icons["header"]["4"] = `<img src="/assets/images/svg/ic_header4.svg" />`;
      icons["bold"] = `<img src="/assets/images/svg/ic_bold.svg"/>`;
      icons["underline"] = `<img src="/assets/images/svg/ic_underline.svg" />`;
      icons["strike"] = `<img src="/assets/images/svg/ic_strike.svg" />`;

      icons["list"][
        "bullet"
      ] = `<img src="/assets/images/svg/ic_list_bullet.svg" />`;
      icons["list"][
        "ordered"
      ] = `<img src="/assets/images/svg/ic_list_ordered.svg" />`;

      // icons["link"] = `<img src="/assets/images/svg/ic_link.svg" />`;

      let options = {
        compatibilityMode: false,
        modules: {
          toolbar: {
            container: toolbar.value,
          },
        },
        debug: false, // 'error', 'warn', 'log', or 'info' , false
        placeholder: "등록하신 질문의 답변을 작성해주세요.",
        theme: "bubble", // bubble or snow
      };

      quillInstance = await new Quill(quillEditor.value, options);

      // note 글자 서식 복사 제한하는 로직
      quillInstance.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        let ops = [];
        delta.ops.forEach((op) => {
          if (op.insert && typeof op.insert === "string") {
            ops.push({
              insert: op.insert,
            });
          }
        });
        delta.ops = ops;
        return delta;
      });

      if (props.content) {
        let convertedDelta = quillInstance.clipboard.convert(props.content);
        quillInstance.setContents(convertedDelta.ops);
      }

      quillInstance.on("text-change", update);

      quillEditor.value.addEventListener("click", () => {
        if (!quillInstance.hasFocus()) {
          const contentLength = quillInstance.getLength();
          quillInstance.setSelection(contentLength + 1, Quill.sources.USER);
        }
      });
    });

    onBeforeUnmount(() => {
      quillInstance.off("text-change", update);
    });

    const update = (delta) => {
      if (delta) {
        if (quillInstance.root.innerHTML === "<p><br></p>") {
          state.form.content = "";
        } else {
          state.form.content = quillInstance.root.innerHTML;
        }

        emit("updateContent", state.form);
      }
    };

    const actions = {
      openLinkForm: (e) => {
        e.stopPropagation();
      },
    };
    return {
      toolbar,
      quillEditor,
      state,
      actions,
    };
  },
};
</script>

<style src="./style.css" scoped></style>
