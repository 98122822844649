<template>
  <div class="share-modal">
    <div class="modal-card">
      <div class="wrapper" :class="{ 'start-sale': state.saleStarted }">
        <div class="box-one">
          <!--	공유하기 카드  -->
          <div class="share-window">
            <h3>
              판매가 시작되었습니다.<br />
              SNS에 초대장을 공유해보세요!
            </h3>
            <div class="share-img-wrapper">
              <div
                class="share-img"
                :style="{ backgroundImage: `url(${club.featuredImage})` }"
              ></div>
              <img
                class="deco1"
                src="/assets/images/club/share-modal-deco1.png"
              />
              <img
                class="deco2"
                src="/assets/images/club/share-modal-deco2.png"
              />
            </div>

            <div class="share-btn-wrapper">
              <ul>
                <li>
                  <button class="btn-kakao" @click="actions.shareKakaoTalk()">
                    <img
                      src="/assets/images/svg/ic_kakao_share.svg"
                      width="40"
                    />
                  </button>
                </li>
                <li>
                  <button class="btn-facebook" @click="actions.shareFacebook()">
                    <img
                      src="/assets/images/svg/ic_facebook_share.svg"
                      width="40"
                    />
                  </button>
                </li>
                <li>
                  <button class="btn-twitter" @click="actions.shareTwitter()">
                    <img
                      src="/assets/images/svg/ic_twitter_share.svg"
                      width="40"
                    />
                  </button>
                </li>
                <li>
                  <button class="btn-line" @click="actions.shareLine()">
                    <img
                      src="/assets/images/svg/ic_line_share.svg"
                      width="40"
                    />
                  </button>
                </li>
                <li>
                  <button class="btn-link" @click="actions.shareLink()">
                    <img
                      src="/assets/images/svg/ic_link_share.svg"
                      width="40"
                    />
                  </button>
                </li>
              </ul>

              <div class="btn-section">
                <button
                  class="bg-gray-010 sub-title-s1"
                  @click="actions.goToClubShow('session')"
                >
                  관리 페이지로 이동
                </button>
                <button
                  class="bg-purple-50 sub-title-s1 text-gray-reverse"
                  @click="actions.goToClubPage()"
                >
                  판매 페이지 보기
                </button>
              </div>
            </div>
          </div>

          <!--	판매 시작전 미리보기 카드  -->
          <div class="preview">
            <div
              class="featured-img"
              :style="{ backgroundImage: `url(${club.featuredImage})` }"
            ></div>

            <div class="club-info">
              <h5>{{ club.tags[0].name }}</h5>
              <h3>
                {{ club.title }}
              </h3>
              <div class="ticket">
                <span class="b-text-2">{{ state.representTicket.title }}</span>
                <h5>{{ state.ticketPrice }}</h5>
              </div>

              <div class="date">
                <span class="txt sub-text-s3 text-gray-second">
                  <calendar-icon fill-color="#818287"></calendar-icon>
                  <span>판매 시작일</span>
                </span>
                <span class="b-title-1">
                  {{ state.salesStartAt.format("YYYY.MM.DD") }}
                </span>
              </div>
              <div v-if="state.sessionType === 'meeting'" class="live-date">
                <span class="txt sub-text-s3 text-gray-second">
                  <calendar-icon fill-color="#818287"></calendar-icon>
                  <span>첫 라이브</span>
                </span>
                <span class="b-title-1">{{ state.meetingSchedule }}</span>
              </div>
              <div class="session">
                <span class="txt sub-text-s3 text-gray-second">
                  <play-icon fill-color="#818287"></play-icon>
                  <span>세션</span>
                </span>
                <span class="title b-title-1">
                  {{ `'${state.sessionResource.title}'` }} </span
                >&nbsp;
                <span v-if="state.sessionCount > 1" class="b-title-1">
                  {{ `외 ${state.sessionCount - 1}개` }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="box-two">
          <h5 class="text-gray-second">유의사항</h5>
          <ul>
            <li>
              <button-accordion
                ref="questionAccordion1"
                :auto-hide="true"
                @toggleContents="actions.toggleAnswer('1')"
              >
                <template #button>
                  <div
                    class="question-section"
                    :class="{ open: state.openAccordion1 }"
                  >
                    <span class="text sub-text-s2">
                      책임의 한계와 법적 고지
                    </span>
                    <span class="arrow-icon">
                      <arrow-icon
                        :direction="state.openAccordion1 ? 'up' : 'down'"
                        fill-color="##0D0D10"
                      ></arrow-icon>
                    </span>
                  </div>
                </template>
                <template #contents>
                  <p class="answer-section text-gray-second b-text-3">
                    빅크㈜는 통신판매중개자이며, 통신판매의 당사자가 아닙니다.
                    콘텐츠, 상품, 상품정보, 거래에 관한 의무와 책임은
                    크리에이터(판매자)에게 있습니다.
                    <br />
                    <br />

                    <u @click="actions.goToDetail()">자세히 보기</u>
                  </p>
                </template>
              </button-accordion>
            </li>
            <li>
              <button-accordion
                ref="questionAccordion2"
                :auto-hide="true"
                @toggleContents="actions.toggleAnswer('2')"
              >
                <template #button>
                  <div
                    class="question-section"
                    :class="{ open: state.openAccordion2 }"
                  >
                    <span class="text sub-text-s2">
                      판매 게시 후에 콘텐츠를 수정할 수 있나요?
                    </span>
                    <span class="arrow-icon">
                      <arrow-icon
                        :direction="state.openAccordion2 ? 'up' : 'down'"
                        fill-color="##0D0D10"
                      ></arrow-icon>
                    </span>
                  </div>
                </template>
                <template #contents>
                  <p class="answer-section text-gray-second b-text-3">
                    판매 게시 후에는 원칙상 수정이 불가합니다. 필요 시 오른쪽
                    하단의
                    <u @click="actions.openServiceCenterPage()">고객센터</u>
                    로 문의 부탁드립니다.
                  </p>
                </template>
              </button-accordion>
            </li>
            <li v-if="state.sessionType === 'meeting'">
              <button-accordion
                ref="questionAccordion3"
                :auto-hide="true"
                @toggleContents="actions.toggleAnswer('3')"
              >
                <template #button>
                  <div
                    class="question-section"
                    :class="{ open: state.openAccordion3 }"
                  >
                    <span class="text sub-text-s2">
                      라이브 전에 리허설을 해볼 수 있나요?
                    </span>
                    <span class="arrow-icon">
                      <arrow-icon
                        :direction="state.openAccordion3 ? 'up' : 'down'"
                        fill-color="##0D0D10"
                      ></arrow-icon>
                    </span>
                  </div>
                </template>
                <template #contents>
                  <p class="answer-section text-gray-second b-text-3">
                    네, 가능합니다. <br />
                    판매가 시작된 이후에 프리미엄 콘텐츠 관리 > 상세 정보에서
                    ‘라이브 시작’ 버튼을 누르시면 언제든지 라이브 리허설을
                    진행해보실 수 있습니다.
                  </p>
                </template>
              </button-accordion>
            </li>
            <li v-if="state.sessionType === 'meeting'">
              <button-accordion
                ref="questionAccordion4"
                :auto-hide="true"
                @toggleContents="actions.toggleAnswer('4')"
              >
                <template #button>
                  <div
                    class="question-section"
                    :class="{ open: state.openAccordion4 }"
                  >
                    <span class="text sub-text-s2">
                      라이브 일정 공지나 입장 안내는 어떻게 해야 하나요?
                    </span>
                    <span class="arrow-icon">
                      <arrow-icon
                        :direction="state.openAccordion4 ? 'up' : 'down'"
                        fill-color="##0D0D10"
                      ></arrow-icon>
                    </span>
                  </div>
                </template>
                <template #contents>
                  <p class="answer-section text-gray-second b-text-3">
                    라이브 일정 및 입장 안내는 별다른 비용 없이 빅크에서
                    자동으로 알림을 전송해드립니다. 크리에이터님은 라이브 시간에
                    ‘라이브 시작’ 버튼만 눌러주시면 됩니다 :)
                  </p>
                </template>
              </button-accordion>
            </li>
            <li>
              <button-accordion
                ref="questionAccordion5"
                :auto-hide="true"
                @toggleContents="actions.toggleAnswer('5')"
              >
                <template #button>
                  <div
                    class="question-section"
                    :class="{ open: state.openAccordion5 }"
                  >
                    <span class="text sub-text-s2">
                      판매 내역이나 구매자 정보를 확인할 수 있나요?
                    </span>
                    <span class="arrow-icon">
                      <arrow-icon
                        :direction="state.openAccordion5 ? 'up' : 'down'"
                        fill-color="##0D0D10"
                      ></arrow-icon>
                    </span>
                  </div>
                </template>
                <template #contents>
                  <p class="answer-section text-gray-second b-text-3">
                    판매된 프리미엄 콘텐츠의 정보는 프리미엄 콘텐츠 관리
                    페이지에서 확인해보실 수 있습니다. <br /><br />
                    <span class="b-text-3">
                      <span>&bull;</span>판매 내역 및 구매자 정보
                    </span>
                    <br />
                    <span class="sub-txt">
                      스튜디오 내 프리미엄 콘텐츠 관리 > 상세 정보 > 멤버
                    </span>
                  </p>
                </template>
              </button-accordion>
            </li>
            <li>
              <button-accordion
                ref="questionAccordion6"
                :auto-hide="true"
                @toggleContents="actions.toggleAnswer('6')"
              >
                <template #button>
                  <div
                    class="question-section"
                    :class="{ open: state.openAccordion6 }"
                  >
                    <span class="text sub-text-s2">
                      정산은 어떻게 받나요?
                    </span>
                    <span class="arrow-icon">
                      <arrow-icon
                        :direction="state.openAccordion6 ? 'up' : 'down'"
                        fill-color="##0D0D10"
                      ></arrow-icon>
                    </span>
                  </div>
                </template>
                <template #contents>
                  <p class="answer-section text-gray-second b-text-3">
                    매월 첫째 주에 전월 판매 수익이 집계됩니다.
                    <br />
                    <br />
                    티켓 판매가 종료된 익월 15일까지 정산금이 지급됩니다.
                    정산금은 스튜디오 가입 후에 지정한 계좌로 입금됩니다.
                  </p>
                </template>
              </button-accordion>
            </li>
            <li>
              <button-accordion
                ref="questionAccordion7"
                :auto-hide="true"
                @toggleContents="actions.toggleAnswer('7')"
              >
                <template #button>
                  <div
                    class="question-section"
                    :class="{ open: state.openAccordion7 }"
                  >
                    <span class="text sub-text-s2">
                      판매 취소는 어떻게 하나요?
                    </span>
                    <span class="arrow-icon">
                      <arrow-icon
                        :direction="state.openAccordion7 ? 'up' : 'down'"
                        fill-color="##0D0D10"
                      ></arrow-icon>
                    </span>
                  </div>
                </template>
                <template #contents>
                  <p class="answer-section text-gray-second b-text-3">
                    프리미엄 콘텐츠 판매 취소는 오른쪽 하단의
                    <u @click="actions.openServiceCenterPage()">고객센터</u>로
                    문의 부탁드립니다.
                  </p>
                </template>
              </button-accordion>
            </li>
          </ul>

          <div class="agree-section">
            <input-checkbox
              :default-value="state.agreeNotice"
              @updateData="(value) => actions.setAgreeNotice(value)"
            ></input-checkbox>
            <span class="sub-text-s2 text-gray-second">
              판매 내용 및 유의 사항을 모두 확인하였습니다.
            </span>
          </div>

          <div class="finish-btn-wrapper">
            <button
              class="modal-back-btn sub-title-s1"
              @click="actions.closeModal()"
            >
              뒤로
            </button>
            <button
              class="modal-finish-btn sub-title-s1"
              :disabled="!state.agreeNotice"
              @click="actions.startSale()"
            >
              판매 시작
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance, onMounted, reactive, ref } from "vue";
import sns from "@/helper/sns";
import { useStore } from "vuex";
import helper from "@/helper";
import swal from "@/helper/swal";
import ButtonAccordion from "../../../../components/console/buttons/ButtonAccordion";
import ArrowIcon from "../../../../components/console/icons/ArrowIcon";
import InputCheckbox from "../../../../components/console/inputs/InputCheckbox";
import CalendarIcon from "../../../../components/console/icons/CalendarIcon";
import PlayIcon from "../../../../components/console/icons/PlayIcon";
import {
  planListPrice,
  planPrice,
  planDiscount,
} from "../../../../helper/plan";
import moment from "moment-timezone";

export default {
  name: "SalesModal",
  components: {
    PlayIcon,
    CalendarIcon,
    InputCheckbox,
    ArrowIcon,
    ButtonAccordion,
  },
  props: {
    club: {
      type: Object,
      required: true,
    },
    salesStartAt: {
      type: String,
      required: false,
    },
    saleStartWithOpen: {
      type: Boolean,
      default: false,
    },
    keywords: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { proxy } = getCurrentInstance();

    const questionAccordion1 = ref();
    const questionAccordion2 = ref();
    const questionAccordion3 = ref();
    const questionAccordion4 = ref();
    const questionAccordion5 = ref();
    const questionAccordion6 = ref();
    const questionAccordion7 = ref();

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      openAccordion1: false,
      openAccordion2: false,
      openAccordion3: false,
      openAccordion4: false,
      openAccordion5: false,
      openAccordion6: false,
      openAccordion7: false,

      agreeNotice: false,
      saleStarted: false,
      representTicket: computed(() => {
        return props.club.allPlans[0];
      }),
      ticketPrice: computed(() => {
        return planPrice(state.representTicket);
      }),
      ticketListPrice: computed(() => {
        return planListPrice(state.representTicket);
      }),
      ticketDiscount: computed(() => {
        return planDiscount(state.representTicket);
      }),
      sessionType: computed(() => {
        return props.club.sessions[0].meeting ? "meeting" : "content";
      }),
      sessionResource: computed(() => {
        return props.club.sessions[0].content
          ? props.club.sessions[0].content
          : props.club.sessions[0].meeting;
      }),
      sessionCount: computed(() => {
        return props.club.sessions.length;
      }),
      meetingSchedule: computed(() => {
        if (state.sessionType === "meeting" && props.club.sessions[0].meeting) {
          let scheduledAt = props.club.sessions[0].meeting.scheduledAt;
          let scheduleFinishAt =
            props.club.sessions[0].meeting.scheduledFinishAt;

          return `${moment(scheduledAt).format("YYYY.MM.DD HH:mm")} ~ ${moment(
            scheduleFinishAt
          ).format("HH:mm")}`;
        }
        return "";
      }),
      salesStartAt: computed(() => {
        return props.saleStartWithOpen ? moment() : props.salesStartAt;
      }),
    });

    onMounted(() => {
      if (!window.Kakao.isInitialized()) {
        sns.kakaoInit();
      }

      if (!window.FB) {
        sns.initFacebookSdk();
      }

      setTimeout(() => {
        questionAccordion1.value.toggleAccordion();
      }, 50);
    });

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      shareKakaoTalk: async () => {
        let sendUrl = await helper.clubShareUrl(state.user, props.club);
        let isListPrice = !!state.representTicket.prices["KRW"].listPrice;
        let discount = state.representTicket.prices["KRW"].listPrice
          ? state.ticketDiscount.replace("%", "")
          : null;

        let kakaoShareOption = {
          objectType: "commerce",
          content: {
            title: `빅크에서 ${state.user.userProfile.displayName}님을 만나보세요!`,
            imageUrl: props.club.featuredImage,
            link: {
              mobileWebUrl: sendUrl,
              webUrl: sendUrl,
            },
          },
          commerce: {
            productName: state.representTicket.title,
          },
          buttons: [
            {
              title: "자세히보기",
              link: {
                webUrl: sendUrl,
                mobileWebUrl: sendUrl,
              },
            },
          ],
        };

        if (isListPrice) {
          kakaoShareOption.commerce.discountRate = parseInt(discount);
          kakaoShareOption.commerce.regularPrice =
            state.representTicket.prices["KRW"].listPrice;
          kakaoShareOption.commerce.discountPrice =
            state.representTicket.prices["KRW"].price;
        } else {
          kakaoShareOption.commerce.regularPrice =
            state.representTicket.prices["KRW"].price;
        }

        window.Kakao.Share.sendDefault(kakaoShareOption);
      },

      shareFacebook: async () => {
        let facebookAppId = process.env.VUE_APP_FACEBOOK_APP_ID;
        let sendUrl = await helper.clubShareUrl(state.user, props.club);

        let url = `https://www.facebook.com/sharer/sharer.php?u=${sendUrl}&display=popup&ref=plugin&src=like&kid_directed_site=0&app_id=${facebookAppId}`;

        window.open(url);
      },
      shareTwitter: async () => {
        let sendText = `빅크에서 ${state.user.userProfile.displayName}님을 만나보세요!`; // 전달할 텍스트
        let sendUrl = await helper.clubShareUrl(state.user, props.club); // 전달할 URL
        window.open(
          "https://twitter.com/intent/tweet?text=" +
            sendText +
            "&url=" +
            sendUrl
        );
      },
      shareLine: async () => {
        let sendText = props.club.title;
        let sendUrl = await helper.clubShareUrl(state.user, props.club);

        window.open(
          `https://line.me/R/msg/text/?${encodeURIComponent(
            sendText + "\n" + sendUrl + "\n"
          )}`
        );
      },
      shareLink: async () => {
        let sendUrl = await helper.clubShareUrl(state.user, props.club);

        helper.copyText(sendUrl);
        swal.createCompleteToast("주소가 복사되었습니다.");
      },
      toggleAnswer: (value) => {
        state[`openAccordion${value}`] = !state[`openAccordion${value}`];
      },
      goToClubShow: (tabName) => {
        emit("goToClubShow", tabName);
      },
      openServiceCenterPage: () => {
        window.open("https://bigcstudio.channel.io/", "_blank");
      },
      setAgreeNotice: (value) => {
        state.agreeNotice = value;
      },
      goToClubPage: () => {
        let clubUrl = helper.getClubWebUrl(state.user, props.club);
        window.open(clubUrl, "_blank");
      },
      goToDetail: () => {
        helper.goToExternalUrl(
          "https://bigc-im.notion.site/2fd1b01e888c4bcab5d4f133a026da83"
        );
      },
      startSale: () => {
        let keywords = props.keywords.reduce((result, current, index) => {
          if (props.keywords[0]) {
            if (props.keywords.length - 1 === index) {
              result += current.val;
            } else {
              result += current.val + ",";
            }
          }

          return result;
        }, "");

        let payload = {
          publishedAt: moment().toJSON(),
          startAt: state.salesStartAt.toJSON(),
          keywords: keywords,
          isTemporary: false,
          status: props.saleStartWithOpen
            ? proxy.$const.SALE_START_NOW
            : proxy.$const.SALE_START_RESERVE,
        };

        store
          .dispatch("newClubs/putClub", {
            clubResourceId: props.club.resourceId,
            data: payload,
          })
          .then(() => {
            state.saleStarted = true;
          });
      },
    };

    return {
      state,
      actions,
      questionAccordion1,
      questionAccordion2,
      questionAccordion3,
      questionAccordion4,
      questionAccordion5,
      questionAccordion6,
      questionAccordion7,
    };
  },
};
</script>

<style src="./style.css" scoped></style>
