<template>
  <icon-base
    icon-name="calendar-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 2.5C6 2.22386 5.77614 2 5.5 2C5.22386  2 5 2.22386 5 2.5V4H3C2.44772 4 2 4.44772 2 5V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V5C14 4.44772 13.5523 4 13 4H11V2.5C11 2.22386 10.7761 2 10.5 2C10.2239 2 10 2.22386 10 2.5V4H6V2.5ZM5.5 5H10.5H13V7H3V5H5.5ZM3 8L3 13H13V8H3Z"
      :fill="fillColor"
    />
  </icon-base>
</template>
<script>
import IconBase from "../../common/IconBase";
export default {
  name: "CalendarIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
  },
};
</script>
