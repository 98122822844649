<template>
  <div class="faq-item-wrapper dark-mode">
    <div v-if="!state.showFaqForm">
      <button
        v-if="!faq.isFixed && !isEditStatus"
        class="handler-btn bg-gray-020"
        :class="{ handle: !faq.isFixed && !isEditStatus }"
      >
        <handler-icon fill-color="#ffffff"></handler-icon>
      </button>
      <button-accordion @toggleContents="actions.toggleAccordion()">
        <template #button>
          <div class="question-section" :class="{ open: state.openAccordion }">
            <span class="q-bullet sub-title-s1">Q.</span>

            <span class="question-wrapper">
              <span class="question sub-title-s1">
                {{ faq.localizations[0].question }}
              </span>
            </span>

            <button
              v-if="!faq.isFixed && !isEditStatus"
              class="edit-btn"
              @click.stop="actions.toggleEditMode()"
            >
              <edit-icon fill-color="#0D0D10"></edit-icon>
            </button>

            <span class="arrow-icon">
              <arrow-icon
                :direction="state.openAccordion ? 'up' : 'down'"
                fill-color="#ffffff"
              ></arrow-icon>
            </span>
          </div>
        </template>
        <template #contents>
          <div class="answer-card faq-editor">
            <div class="ql-editor">
              <p v-html="state.answerHtml"></p>
            </div>
          </div>
        </template>
      </button-accordion>

      <button
        v-if="!faq.isFixed && !isEditStatus"
        class="delete-btn bg-gray-020"
        @click="actions.openDeleteModal()"
      >
        <delete-icon fill-color="#ffffff"></delete-icon>
      </button>
    </div>

    <div v-if="state.showFaqForm">
      <faq-form
        :faq="faq"
        :club-resource-id="clubResourceId"
        @updating="actions.updateSaveStatus()"
        @cancelFaqWriting="actions.cancelFaqWriting()"
        @updateFaqs="actions.closeFaqForm()"
      ></faq-form>
    </div>

    <teleport to="#teleport">
      <warning-modal
        v-if="state.showFaqDeleteModal"
        warning-title="해당 FAQ 를 삭제하시겠어요?"
        confirm-text="삭제"
        :warning-text="`${faq.localizations[0].question}을 삭제합니다.`"
        @confirm="actions.deleteFaq()"
        @hideModal="actions.closeFaqDeleteModal()"
      >
      </warning-modal>
    </teleport>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import ButtonAccordion from "@/components/console/buttons/ButtonAccordion";
import { useStore } from "vuex";
import ArrowIcon from "@/components/console/icons/ArrowIcon";
import FaqForm from "../FaqForm/FaqForm";
import DeleteIcon from "@/components/console/icons/DeleteIcon";
import HandlerIcon from "@/components/console/icons/HandlerIcon";
import EditIcon from "@/components/console/icons/EditIcon";
import helper from "@/helper";
import WarningModal from "@/components/console/modals/WarningModal";

export default {
  name: "FaqItem",
  components: {
    WarningModal,
    EditIcon,
    HandlerIcon,
    DeleteIcon,
    FaqForm,
    ArrowIcon,
    ButtonAccordion,
  },
  props: {
    faq: {
      type: Object,
      require: true,
    },
    clubResourceId: {
      type: String,
      required: true,
    },
    isEditStatus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["editStatusUpdate", "updating", "updateFaqs"],
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      openAccordion: false,
      showFaqDeleteModal: false,
      showFaqForm: false,
      answerHtml: computed(() => {
        return helper
          .nl2br(props.faq.localizations[0].answerHtml)
          .replaceAll("\\", "");
      }),
    });

    const actions = {
      toggleAccordion: () => {
        state.openAccordion = !state.openAccordion;
      },
      toggleEditMode: () => {
        state.showFaqForm = true;
        emit("editStatusUpdate", state.showFaqForm);
        state.openAccordion = false;
      },
      cancelFaqWriting: () => {
        state.showFaqForm = false;
        emit("editStatusUpdate", state.showFaqForm);
        state.openAccordion = false;
      },
      openDeleteModal: () => {
        state.showFaqDeleteModal = true;
      },
      closeFaqDeleteModal: () => {
        state.showFaqDeleteModal = false;
      },
      updateSaveStatus: () => {
        emit("updating");
      },
      deleteFaq: () => {
        emit("updating");
        store
          .dispatch("newClubs/deleteClubFaq", {
            resourceId: props.faq.resourceId,
            clubResourceId: props.clubResourceId,
          })
          .then(() => {
            state.showFaqDeleteModal = false;
            emit("updateFaqs");
          });
      },
      closeFaqForm: () => {
        emit("updateFaqs");
        state.showFaqForm = false;
        emit("editStatusUpdate", state.showFaqForm);
        state.openAccordion = false;
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
