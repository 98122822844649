<template>
  <div class="cms-editor__toolbar">
    <button-basic
      class="toolbar-item"
      bg-color="transparent"
      hover-bg-color="#39393d"
      padding="0"
      @action="actions.openImageSelector()"
    >
      <template #icon>
        <image-icon width="28" height="28"></image-icon>
      </template>
    </button-basic>

    <tooltip
      :is-show="state.showTooltip"
      :auto-hide="true"
      :triggers="['click']"
      @hideEvent="actions.videoButtonNoneActive()"
    >
      <template #button>
        <button-basic
          class="toolbar-item"
          :bg-color="state.showTooltip ? '#8E1EFF' : 'transparent'"
          hover-bg-color="#39393d"
          padding="0"
          @action="actions.toggleVideoInput()"
        >
          <template #icon>
            <play-icon width="28" height="28" fill-color="#ffffff"></play-icon>
          </template>
        </button-basic>
      </template>
      <template #tooltip>
        <div class="video-url-wrapper">
          <input
            type="url"
            placeholder="동영상 url 입력 후 엔터"
            :value="state.videoUrl"
            @input="actions.updateVideoUrl($event)"
            @keypress.enter="actions.embedVideo($event)"
          />
          <small v-if="state.videoEmbedError" class="text-red-50">
            {{ state.videoEmbedError }}
          </small>
        </div>
      </template>
    </tooltip>

    <button-basic
      class="toolbar-item"
      bg-color="transparent"
      hover-bg-color="#39393d"
      padding="0"
      @action="actions.uploadVideoInStorage"
    >
      <template #icon>
        <svg
          width="28"
          height="28"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.5002 6.94995C2.94202 6.94995 3.3002 7.30812 3.3002 7.74995V12.3998C3.33526 12.4222 3.40303 12.45 3.5002 12.45H12.5002C12.5974 12.45 12.6651 12.4222 12.7002 12.3998V7.74995C12.7002 7.30812 13.0584 6.94995 13.5002 6.94995C13.942 6.94995 14.3002 7.30812 14.3002 7.74995V12.4642C14.3002 13.509 13.3038 14.05 12.5002 14.05H3.5002C2.69656 14.05 1.7002 13.509 1.7002 12.4642V7.74995C1.7002 7.30812 2.05837 6.94995 2.5002 6.94995Z"
            fill="#ffffff"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.43451 1.23431C7.74693 0.921895 8.25346 0.921895 8.56588 1.23431L10.5659 3.23431C10.8783 3.54673 10.8783 4.05327 10.5659 4.36569C10.2535 4.6781 9.74693 4.6781 9.43451 4.36569L8.0002 2.93137L6.56588 4.36569C6.25346 4.6781 5.74693 4.6781 5.43451 4.36569C5.12209 4.05327 5.12209 3.54673 5.43451 3.23431L7.43451 1.23431Z"
            fill="#ffffff"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.0501 9.80005C8.49193 9.80005 8.8501 9.44188 8.8501 9.00005L8.8501 2.60005C8.8501 2.15822 8.49193 1.80005 8.0501 1.80005C7.60827 1.80005 7.2501 2.15822 7.2501 2.60005L7.2501 9.00005C7.2501 9.44188 7.60827 9.80005 8.0501 9.80005Z"
            fill="#ffffff"
          />
        </svg>
      </template>
    </button-basic>

    <input
      ref="videoFileInput"
      class="video-file-input"
      name="video-file-input"
      type="file"
      accept="video/mp4"
      @change="onFileChange"
    />

    <button-basic
      class="toolbar-item"
      :bg-color="state.showTooltip ? '#8E1EFF' : 'transparent'"
      hover-bg-color="#39393d"
      padding="0"
      @action="actions.insertHrTag()"
    >
      <template #icon>
        <divider-icon
          width="28"
          height="28"
          fill-color="#ffffff"
        ></divider-icon>
      </template>
    </button-basic>

    <!--    <button-->
    <!--      class="toolbar-item btn p-0 my-2"-->
    <!--      v-bind:class="{ active: state.activeQuotation }"-->
    <!--    >-->
    <!--      <img class="toolbar-icon" src="/assets/images/svg/ic_quotation.svg" />-->
    <!--    </button>-->
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import Tooltip from "../../../../components/console/tooltip/Tooltip";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import ImageIcon from "@/components/console/icons/ImageIcon.vue";
import PlayIcon from "@/components/console/icons/PlayIcon.vue";
import DividerIcon from "@/components/console/icons/DividerIcon.vue";
import ApiService from "@/api";
import axios from "axios";
import swal from "@/helper/swal";

export default {
  name: "CmsEditorToolbar",
  components: { DividerIcon, PlayIcon, ImageIcon, ButtonBasic, Tooltip },
  props: {
    clubResourceId: {
      type: String,
      default: "",
    },
  },
  emits: ["openImageSelector", "embedVideo", "embedHlVideo"],
  setup(props, { emit }) {
    const videoFileInput = ref(null);

    const state = reactive({
      videoUrl: "",
      showTooltip: false,
      videoEmbedError: "",
      activeQuotation: false,
      triggerDeleteUploadingMultipart: false,
      isNetworkError: false,
    });

    const getVideoUrl = (url) => {
      let match =
        url.match(
          /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/
        ) ||
        url.match(
          /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/
        ) ||
        url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#]*).*/);

      if (match && match[2].length === 11) {
        return "https" + "://www.youtube.com/embed/" + match[2] + "?showinfo=0";
      }
      if (
        (match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/))
      ) {
        return (
          (match[1] || "https") + "://player.vimeo.com/video/" + match[2] + "/"
        );
      }
      console.log(url);
      return url;
    };

    const triggerVideoFileInput = () => {
      videoFileInput.value.click();
    };

    const onFileChange = (event) => {
      handleFiles(event.target.files || event.dataTransfer.files);
    };

    const handleFiles = (fileList) => {
      const files = fileList;
      let file = files[0];
      if (file.type !== "video/mp4") {
        swal.errorToast("파일 확장자를 확인해주세요.");
        return;
      }
      uploadFile(file);
    };

    const uploadFile = async (fileData) => {
      state.errorMessage = "";
      let file = fileData;

      // 6MB = 1024 * 1024 * 6;
      // 10KB = 1024 * 10
      const chunkSize =
        file.size > 1024 * 1024 * 1024 ? 1024 * 1024 * 60 : 1024 * 1024 * 6;
      const chunks = [];
      let offset = 0;

      while (offset < file.size) {
        let chunk = file.slice(offset, offset + chunkSize);
        chunks.push(chunk);
        offset += chunkSize;
      }

      const dataLength = chunks.length;

      state.uploadFileLength = chunks.length;

      const clubId = props.clubResourceId;
      const timestamp = Date.now();
      const env = process.env.NODE_ENV === "development" ? "dev" : "prd";
      const filePath = `${env}_club_${clubId}`;
      const fileName = `${filePath}_time_${timestamp}`;
      const extension = "mp4";
      const encodingConfig = "AVC_FHD_2Pass_30fps";

      const payloadMultipart = {
        fileName: `${fileName}.${extension}`,
      };

      const payload = {
        uploadId: "",
        uploadKey: "",
      };

      const payloadParts = [];
      const params = { isPublicHighlight: true };

      try {
        const res = await ApiService.postMultipart(params, payloadMultipart);
        const data = res.data.data;
        payload.uploadId = data.uploadId;
        payload.uploadKey = data.key;

        // data 보내기 사이클
        for (let i = 0; i < dataLength; i++) {
          if (state.triggerDeleteUploadingMultipart) {
            state.triggerDeleteUploadingMultipart = false;
            videoFileInput.value.value = "";
            return;
          }
          try {
            const res = await ApiService.getMultipartPresignedUrl(
              payload.uploadId,
              i + 1,
              {
                key: payload.uploadKey,
              }
            );
            const data = res.data.data;
            const { signedUrl: preSignedUrl } = data;
            const respos = await retryAxiosPut(i, preSignedUrl, chunks[i], {
              headers: {
                "Content-Type": "video/mp4",
              },
            });

            let resEtag = respos.headers.etag;
            let eTag = extractEtag(resEtag);
            payloadParts[i] = { ETag: eTag, PartNumber: i + 1 };
          } catch (error) {
            console.error(error);
          }
        }

        if (payloadParts.length == dataLength) {
          try {
            const res = await ApiService.completeMultipart(
              payload.uploadId,
              { key: payload.uploadKey },
              { parts: payloadParts }
            );
            const data = res.data.data;
            console.log(data);
            const url = `${process.env.VUE_APP_NCLOUD_UPLOADED_VIDEO_HIGHLIGHT_URL}/${filePath}/${fileName}_${encodingConfig}.${extension}`;
            emit("embedHlVideo", url);
            swal.createCompleteToast("업로드 되었습니다.");
          } catch (error) {
            console.error(error);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    const extractEtag = (resEtag) => {
      return resEtag.replace(/"/g, "");
    };

    const retryAxiosPut = async (
      i,
      url,
      data,
      options,
      retries = 3,
      delay = 1000
    ) => {
      for (let attempt = 0; attempt < retries; attempt++) {
        try {
          const response = await axios.put(url, data, options);
          return response;
        } catch (error) {
          state.isNetworkError = true;
          if (attempt < retries - 1) {
            await new Promise((resolve) => setTimeout(resolve, delay));
          } else {
            if (state.isNetworkError) {
              swal.errorToast("파일을 다시 업로드 해주세요.");
            }
            throw error;
          }
        }
      }
    };

    const actions = {
      openImageSelector: () => {
        emit("openImageSelector");
      },
      embedVideo: (e) => {
        let videoUrl = getVideoUrl(e.target.value);

        if (videoUrl) {
          emit("embedVideo", videoUrl);
          state.videoUrl = "";
          state.showTooltip = false;
        } else {
          state.videoEmbedError = "유효하지 않은 동영상 링크 입니다. ";
          state.videoUrl = "";
        }
      },
      toggleVideoInput: () => {
        state.showTooltip = !state.showTooltip;
        if (!state.showTooltip) {
          state.videoUrl = "";
        }
      },
      videoButtonNoneActive: () => {
        state.showTooltip = false;
      },
      insertHrTag: () => {
        emit("insertHrTag");
      },
      updateVideoUrl: (e) => {
        if (state.videoEmbedError) {
          state.videoEmbedError = "";
        }
        state.videoUrl = e.target.value;
      },
      uploadVideoInStorage: () => {
        triggerVideoFileInput();
      },
    };

    return { state, actions, videoFileInput, onFileChange };
  },
};
</script>

<style src="./style.css" scoped></style>
