import store from "../store";
import Cookies from "js-cookie";
import router from "@/router/index.ts";

class AuthService {
  async login(token, redirect, remember = true) {
    await store.dispatch("auth/saveToken", {
      token,
      remember: remember,
    });
    await store.dispatch("auth/fetchUser");
    if (store.getters["auth/user"].aliasName) {
      Cookies.remove("onBoardingAlias");
    }

    if (!redirect) {
      const appName = router.currentRoute._value.name.split(".")[0];
      await router.push({ name: `${appName}.clubs` });
    } else {
      const redirectDecoded = decodeURIComponent(redirect);
      await router.push({ path: redirectDecoded });
    }
  }
}

export default new AuthService();
