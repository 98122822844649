<template>
  <div
    class="plan-item-wrapper"
    :class="{ active: selected, 'sold-out': state.soldOut }"
    @click="actions.selectPlan()"
  >
    <span
      class="sub-text-s2"
      :class="state.soldOut ? 'text-gray-third' : 'text-default'"
      >{{ plan.title }}</span
    >
    <div v-if="!state.soldOut" class="plan-meta-info">
      <span v-if="state.discount" class="text-red-50 sub-text-s2">
        {{ state.discount }}
      </span>
      <span
        v-if="state.listPrice"
        class="list-price text-gray-second sub-text-s2"
      >
        {{ state.listPrice }}
      </span>
      <span v-if="state.listPrice">
        <text-divider></text-divider>
      </span>
      <span class="price sub-text-s2">{{ state.price }}</span>
      <check-icon
        v-if="selected"
        fill-color="#ffffff"
        view-box="2 2 12 12"
        width="8.25"
        height="8.25"
      ></check-icon>
    </div>

    <div v-if="state.soldOut" class="sold-out">
      <span class="sub-text-s2">판매종료</span>
    </div>
  </div>
</template>

<script>
import {
  planDiscount,
  planListPrice,
  planPrice,
  planSoldOut,
} from "@/helper/plan";
import { computed, reactive } from "vue";
import TextDivider from "../../../../components/console/dividers/TextDivider";
import CheckIcon from "../../../../components/console/icons/CheckIcon";

export default {
  name: "PlanDropdownItem",
  components: { CheckIcon, TextDivider },
  props: {
    plan: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const state = reactive({
      discount: computed(() => {
        return planDiscount(props.plan);
      }),
      listPrice: computed(() => {
        return planListPrice(props.plan);
      }),
      price: computed(() => {
        return planPrice(props.plan);
      }),
      soldOut: computed(() => {
        return planSoldOut(props.plan);
      }),
    });

    const actions = {
      selectPlan: () => {
        if (!state.soldOut) {
          emit("selectPlan", props.plan.resourceId);
        }
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
