import axios from "axios";
import ApiService from "../api";
import AuthService from "../services/AuthService";
import { OAuth2Client } from "google-auth-library";

const client = new OAuth2Client({
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope:
    "https://www.googleapis.com/auth/contacts https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/directory.readonly https://www.googleapis.com/auth/user.addresses.read https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.emails.read https://www.googleapis.com/auth/user.gender.read https://www.googleapis.com/auth/user.organization.read https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
});

class sns {
  initFacebookSdk() {
    return new Promise((resolve) => {
      // wait for facebook sdk to initialize before starting the vue app
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: process.env.VUE_APP_FACEBOOK_APP_ID,
          scope: "id,name,gender,user_birthday,email",
          cookie: true,
          xfbml: true,
          version: "v14.0",
        });

        // auto authenticate with the api if already logged in with facebook
        window.FB.getLoginStatus(({ authResponse }) => {
          if (authResponse) {
            resolve();
          } else {
            resolve();
          }
        });
      };

      // load facebook sdk script
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    });
  }

  loginToFacebook(FB, userID, token, cb) {
    FB.api(
      `/${userID}`,
      "get",
      { fields: "id,name,email,gender,picture", access_token: token },
      async (response) => {
        cb(response);
      }
    );
  }

  appleId(redirectUri) {
    const params = {
      clientId: process.env.VUE_APP_APPLE_CLIENT_ID,
      scope: "email name",
      redirectURI: redirectUri,
      state: "state",
      nonce: "nonce",
      usePopup: true,
    };

    window.AppleID.auth.init(params);
  }

  appleTokenParseJwt(token) {
    let base64UrlBody = token.split(".")[1];
    let base64UrlHead = token.split(".")[0];

    let base64Body = base64UrlBody.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayloadBody = decodeURIComponent(
      atob(base64Body)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    let base64Head = base64UrlHead.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayloadHead = decodeURIComponent(
      atob(base64Head)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return {
      header: JSON.parse(jsonPayloadHead),
      payload: JSON.parse(jsonPayloadBody),
    };
  }

  async loginToApple(appleUser) {
    const payLoad = {
      providerId: appleUser.payload.sub,
      providerEmail: appleUser.payload.email,
      providerName: "",
      providerNickname: "",
      providerAvatar: "",
      provider: "apple",
      providerPhoneNumber: null,
    };
    const res = await ApiService.postAuthSocialApp(payLoad);
    if (res.data.message) {
      console.error(`sns login API Error (google) : ${res.data.message}`);
    }
    if (!res.data.data) return;

    await AuthService.login(res.data.data.token, "/console/clubs");
  }

  kakaoInit() {
    window.Kakao.init(process.env.VUE_APP_KAKAO_KEY);
  }

  async kakaoAccessToken(code) {
    const kakaoHeader = {
      Authorization: process.env.VUE_APP_KAKAO_KEY,
      "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
    };
    try {
      const data = {
        grant_type: "authorization_code",
        client_id: process.env.VUE_APP_KAKAO_KEY,
        redirect_uri: `${process.env.VUE_APP_URL}/auth/handle/kakao`,
        code: code,
      };

      const queryString = Object.keys(data)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(data[k]))
        .join("&");
      const result = await axios.post(
        "https://kauth.kakao.com/oauth/token",
        queryString,
        { headers: kakaoHeader }
      );
      await window.Kakao.Auth.setAccessToken(result.data.access_token);
    } catch (e) {
      return e;
    }
  }

  async loginToKakao() {
    let userInfo = "";
    await window.Kakao.API.request({
      url: "/v2/user/me",
      success: function (response) {
        userInfo = response;
      },
      fail: function (error) {
        console.log(error);
      },
    });

    const payLoad = {
      providerId: userInfo.id,
      providerEmail: userInfo.kakao_account.email,
      providerName:
        !!userInfo.kakao_account.profile &&
        !!userInfo.kakao_account.profile.nickName
          ? userInfo.kakao_account.profile.nickName
          : "",
      providerNickname:
        !!userInfo.kakao_account.profile &&
        !!userInfo.kakao_account.profile.nickName
          ? userInfo.kakao_account.profile.nickName
          : "",
      providerAvatar:
        !!userInfo.kakao_account.profile &&
        !!userInfo.kakao_account.profile.profile_image_url
          ? userInfo.kakao_account.profile.profile_image_url
          : "",
      providerGender: userInfo.kakao_account.gender,
      provider: "kakao",
      providerPhoneNumber: userInfo.kakao_account.phone_number,
    };
    const res = await ApiService.postAuthSocialApp(payLoad);
    if (res.data.message) {
      console.error(`sns login API Error (kakao): ${res.data.message}`);
    }

    if (!res.data.data) return;
    await AuthService.login(res.data.data.token, "/console/clubs");
  }

  async loginToGoogle(result) {
    client.setCredentials({ access_token: result.access_token });

    const userinfo = await client.request({
      url: "https://www.googleapis.com/oauth2/v3/userinfo",
      scope: `${result.scope} genders https://www.googleapis.com/auth/user.gender.read`,
    });

    // const gender = await client.request({
    //   url: "https://people.googleapis.com/v1/people/me",
    //   params: {
    //     personFields: "genders",
    //     key: process.env.VUE_APP_GOOGLE_API_KEY,
    //   },
    // });

    // TODO ANDREW gender 정보 추가(google cloud 에서 OAuth 동의화면에 범위 추가)
    let payload = {
      providerId: userinfo.data.sub,
      providerEmail: userinfo.data.email,
      providerName: userinfo.data.given_name,
      providerNickname: userinfo.data.family_name,
      providerAvatar: userinfo.data.picture,
      provider: "google",
      providerPhoneNumber: null,
    };

    const res = await ApiService.postAuthSocialApp(payload);
    if (res.data.message) {
      console.error(`sns login API Error (google) : ${res.data.message}`);
    }
    if (!res.data.data.token) return;

    await AuthService.login(res.data.data.token, "/console/clubs");
  }
}

export default new sns();
