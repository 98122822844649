<template>
  <base-modal
    title="판매 설정"
    sub-title=" 판매를 바로 시작하거나 예약 일시를 설정할 수 있습니다."
    position="top-right"
    @closeModal="actions.closeModal()"
  >
    <template #modalBody>
      <div class="time-title sub-text-s2">
        <span>판매 시간</span><span class="text-purple-50">*</span>
      </div>
      <div class="radio-group">
        <label>
          <input
            v-model="state.registerType"
            type="radio"
            name="publicStatus"
            value="current"
          />
          <span class="sub-text-s2">현재</span>
        </label>

        <label>
          <input
            v-model="state.registerType"
            type="radio"
            value="reservation"
            name="publicStatus"
          />
          <span class="sub-text-s2"> 예약 </span>
        </label>
      </div>

      <div v-if="state.showRegisterDateInput" class="date-picker-wrapper">
        <div class="date">
          <label class="sub-text-s2 text-gray-second">판매일</label>
          <input-date-picker
            placeholder="판매일을 입력해 주세요."
            :date="startAt"
            :enable-time="false"
            format="YYYY-MM-DD"
            :error="!!state.errorMessage"
            @updateDate="
              (value) => {
                actions.setStartAt(value, 'date');
              }
            "
          ></input-date-picker>
        </div>

        <div class="time">
          <label class="sub-text-s2 text-gray-second">판매 시간</label>
          <input-date-picker
            placeholder="판매시간을 입력해 주세요."
            :date="startAt"
            :no-calendar="true"
            format="HH:mm"
            :error="!!state.errorMessage"
            @updateDate="
              (value) => {
                actions.setStartAt(value, 'time');
              }
            "
          ></input-date-picker>
        </div>
      </div>
      <p v-if="state.errorMessage" class="text-red-50 sub-title-s3">
        {{ state.errorMessage }}
      </p>

      <div class="tag-section">
        <div>
          <span class="sub-text-s2">태그</span>
          <p class="sub-text-s3 text-gray-second">
            구성은 최대 3개까지 작성할 수 있습니다.
          </p>
        </div>

        <input-tags
          class="tags-input"
          placeholder="태그를 입력해 주세요."
          :tags="clubKeywords"
          :max-tags="3"
          @addTag="(tag) => actions.addClubKeywords(tag)"
          @removeTag="(tag) => actions.removeClubKeywords(tag)"
        ></input-tags>
      </div>

      <div class="btn-section">
        <button-basic
          class="cancel"
          text="취소"
          bg-color="#ECF1F4"
          color="#0d0d0d"
          @action="actions.closeModal()"
        ></button-basic>

        <button-basic
          class="start"
          text="판매 시작"
          @action="actions.clubRegister()"
        ></button-basic>
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "../../../../components/common/BaseModal.vue";
import { computed, reactive, watch } from "vue";
import InputTags from "../../../../components/console/inputs/InputTags";
import moment from "moment-timezone";
import InputDatePicker from "../../../../components/console/inputs/InputDatePicker";
import swal from "@/helper/swal";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default {
  name: "RegisterModal",
  components: { ButtonBasic, InputDatePicker, InputTags, BaseModal },
  props: {
    club: {
      type: Object,
      required: true,
    },
    registerType: {
      type: String,
      required: true,
    },
    startAt: {
      type: [Object, String], // moment Object
      default: "",
    },
    clubKeywords: {
      type: Array,
      required: () => {
        return [];
      },
    },
  },
  emits: [
    "setSelectedPublicStatus",
    "hideModal",
    "setStartAt",
    "addClubKeyword",
    "removeClubKeyword",
    "setSaleWithOpen",
    "openSalesModal",
  ],
  setup(props, { emit }) {
    const state = reactive({
      registerType: props.registerType,
      showRegisterDateInput: computed(() => {
        return state.registerType === "reservation";
      }),
      reservePublicDate: computed(() => {
        return props.startAt ? props.startAt.toJSON() : "";
      }),
      errorMessage: "",
    });

    watch(
      () => state.registerType,
      (currentVal) => {
        emit("setSelectedPublicStatus", currentVal);
      }
    );

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      setStartAt: (date, type) => {
        let dateString = "";
        let timeString = "";
        let result = "";

        if (type === "date") {
          dateString = moment(date).format("YYYY-MM-DD");
          if (props.startAt) {
            timeString = moment(props.startAt).format("HH:mm");
          } else {
            timeString = "00:00";
          }
        }

        if (type === "time") {
          if (props.startAt) {
            dateString = moment(props.startAt).format("YYYY-MM-DD");
          } else {
            dateString = moment(date).format("YYYY-MM-DD");
          }
          timeString = moment(date).format("HH:mm");
        }

        result = `${dateString} ${timeString}`;

        emit("setStartAt", result);
      },
      addClubKeywords: (data) => {
        emit("addClubKeyword", data);
      },
      removeClubKeywords: (data) => {
        emit("removeClubKeyword", data);
      },
      clubRegister: () => {
        if (
          (state.registerType === "reservation" && !state.reservePublicDate) ||
          (state.reservePublicDate &&
            state.reservePublicDate < moment(new Date()).toJSON())
        ) {
          state.errorMessage = "현재 시간 이후로 설정해주세요.";
          return;
        }

        if (!props.club.title) {
          swal.messageErrorAlert("제목은 필수 입니다.");
          emit("hideModal");
          return;
        }

        if (props.club.allPlans.length === 0) {
          swal.messageErrorAlert("티켓은 최소 한개 이상 만들어야 합니다!");
          emit("hideModal");
          return;
        }

        if (props.club.sessions.length === 0) {
          swal.messageErrorAlert("최소 한개 이상의 세션이 있어야합니다!");
          emit("hideModal");
          return;
        }

        if (props.club.tags.length === 0) {
          swal.messageErrorAlert("최소 한개 이상의 카테고리를 선택하세요!");
          emit("hideModal");
          return;
        }

        if (state.registerType === "current") {
          emit("setSaleWithOpen");
        }

        emit("openSalesModal");
      },
    };

    return { state, actions, moment };
  },
};
</script>

<style src="./style.css" scoped></style>
