<template>
  <div id="cms-container" ref="clubEditWrapper" class="dark-mode console">
    <page-loading v-if="state.pageLoading"></page-loading>
    <div v-if="!state.pageLoading" class="cms bg-gray-01">
      <!--	header  -->
      <div class="cms-header">
        <div class="left-section">
          <button-text :is-icon="true" text-size="s1" @click="goToBack()">
            <template #icon>
              <arrow-icon
                direction="left"
                fill-color="#0d0d0d"
                width="24"
                height="24"
                stroke-size="normal"
              ></arrow-icon>
            </template>
          </button-text>
          <bigc-logo class="logo" width="78px"></bigc-logo>
          <span class="status sub-title-s1 text-gray-070">
            {{ state.updateStatus }}
          </span>
        </div>

        <div class="pc-img">
          <img src="/assets/images/svg/ic_pc.svg" width="32" height="32" />
        </div>

        <div class="right-section">
          <button-text
            v-if="state.club.isTemporary"
            class="temporary-delete"
            text="임시 저장 삭제"
            text-size="s1"
            :is-icon="true"
            icon-position="front"
            @click="actions.openDeleteClubModal()"
          >
            <template #icon>
              <delete-icon stroke-size="normal"></delete-icon>
            </template>
          </button-text>

          <button-basic
            class="guide"
            text="작성가이드"
            border-color="#E6EAEF"
            bg-color="#ffffff"
            color="#0d0d0d"
            :border="true"
            @action="actions.goToCmsGuide()"
          ></button-basic>

          <button-basic
            v-if="!state.club.isTemporary"
            class="out"
            text="나가기"
            bg-color="#ECF1F4"
            color="#0d0d0d"
            @action="goToBack()"
          ></button-basic>

          <button-basic
            v-if="state.club.isTemporary"
            class="start"
            text="판매 시작"
            @action="actions.openRegisterModal()"
          ></button-basic>
        </div>
      </div>
      <!--	end header  -->

      <div class="cms-body">
        <div class="main-section">
          <div
            class="featured-img"
            :class="{ empty: !state.prevFeaturedImage }"
            :style="`background-image: url(${state.prevFeaturedImage});`"
            @click="actions.openFileStack()"
          >
            <div class="img-upload">
              <camera-button></camera-button>
              <div v-if="!state.prevFeaturedImage" class="description">
                <h3>섬네일 이미지 업로드</h3>
                <p class="text-default">
                  * 권장사이즈: 600x600<br />
                  * 업로드 가능한 파일 : png, jpg
                </p>
              </div>
            </div>
          </div>
          <div class="title-ticket">
            <div class="user-area">
              <div class="user-profile">
                <avatar :avatar-img="state.user.avatar"></avatar>
                <span class="text-default sub-title-s1">
                  {{ helper.displayUserName(state.user) }}
                </span>
              </div>
              <div class="user-icons">
                <span>
                  <img src="/assets/images/svg/ic_space_thin.svg" width="20" />
                </span>
                <span>
                  <img src="/assets/images/svg/ic_share_thin.svg" width="20" />
                </span>
                <span>
                  <img src="/assets/images/svg/ic_heart_thin.svg" width="20" />
                </span>
              </div>
            </div>
            <div class="category">
              <button
                ref="interestTagToggleBtn"
                class="toggle-btn"
                @click="toggleInterestTags()"
              >
                <h5 class="category-txt">
                  {{
                    state.selectedInterestTags.length > 0
                      ? state.selectedInterestTags[0].name
                      : "카테고리 설정"
                  }}
                </h5>
                <span class="category-arrow text-default">
                  <i class="fa-solid fa-chevron-down"></i>
                </span>
              </button>

              <div
                v-show="state.showInterestTags"
                ref="interestTagsCard"
                class="interests-card bg-gray-03"
              >
                <h2 class="title">카테고리 설정</h2>
                <ul>
                  <li
                    v-for="interestTag in state.clubMeta.interestTags"
                    :key="`interest-tag-${interestTag.id}`"
                  >
                    <button
                      class="tag-btn text-default"
                      :class="
                        state.selectedInterestTags.findIndex((item) => {
                          return item.id === interestTag.id;
                        }) >= 0
                          ? 'interest-btn-active'
                          : 'interest-btn'
                      "
                      @click="actions.setInterestTag(interestTag)"
                    >
                      {{ `${interestTag.name}` }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <inline-textarea
              :placeholder="state.placeholderTitle"
              :enable-enter="false"
              :default-value="state.clubForm.title"
              :max-length="38"
              @updateData="(title) => actions.updateClubTitle({ title: title })"
            ></inline-textarea>

            <div class="ticket-area">
              <div class="select-box">
                <button-accordion
                  ref="planListAccordion"
                  :auto-hide="true"
                  :is-floating="true"
                  @toggleContents="actions.togglePlansSelectBox()"
                >
                  <template #button>
                    <button class="select-btn" :disabled="!state.selectedPlan">
                      <span class="sub-text-s2">
                        {{
                          state.selectedPlan
                            ? state.selectedPlan.title
                            : "생성된 티켓이 없습니다."
                        }}
                      </span>
                      <arrow-icon
                        :direction="state.showPlansList ? 'up' : 'down'"
                        fill-color="#5A5B5F"
                      ></arrow-icon>
                    </button>
                  </template>
                  <template #contents>
                    <div class="plan-list-card">
                      <ul>
                        <li
                          v-for="plan in state.club.allPlans"
                          :key="`plan-${plan.resourceId}`"
                        >
                          <plan-dropdown-item
                            :plan="plan"
                            :selected="
                              plan.resourceId === state.selectedPlan.resourceId
                            "
                            @selectPlan="
                              (planResourceId) =>
                                actions.setSelectedPlan(planResourceId)
                            "
                          ></plan-dropdown-item>
                        </li>
                      </ul>
                    </div>
                  </template>
                </button-accordion>
              </div>

              <div v-if="!!state.selectedPlan" class="show-ticket">
                <selected-plan :plan="state.selectedPlan"></selected-plan>

                <button
                  class="admin-btn sub-title-s1"
                  :disabled="state.isStartedSale && !state.isAdmin"
                  @click="actions.openPlanListModal()"
                >
                  티켓 관리
                </button>
              </div>

              <div v-if="!state.selectedPlan" class="ticket-empty">
                <span class="sub-title-s2 text-default">
                  생성된 티켓이 없습니다.
                </span>
              </div>
            </div>
          </div>
        </div>

        <hr class="hr-second" />

        <div class="body-section">
          <div class="description-section">
            <div class="description-header">
              <h1>Story</h1>
              <div class="sample-link" @click="actions.goToSampleGuide()">
                <span class="sub-title-s2">샘플 보기</span>
                <help-icon fill-color="#ffffff"></help-icon>
              </div>
            </div>
            <cms-editor
              :placeholder="state.placeholderDescription"
              :content="state.clubForm.description"
              :club-resource-id="state.club.resourceId"
              @updateContent="(form) => actions.updateClubDescription(form)"
            ></cms-editor>
          </div>

          <div ref="sessionWrapper" class="session-section">
            <tooltip
              placement="right"
              :auto-hide="false"
              :is-show="state.isStartedSale"
              :distance="20"
            >
              <template #button>
                <h1>Session</h1>
              </template>
              <template #tooltip>
                <span class="sub-text-s3 text-gray-second">
                  이미 판매된 콘텐츠의 세션은 수정할 수 없습니다. 필요 시 우측
                  하단 고객센터로 문의주세요.
                </span>
              </template>
            </tooltip>
            <div v-if="state.clubSessions.length === 0" class="empty-card">
              <p class="text-default sub-title-s2">
                티켓에 등록할 콘텐츠를 추가해주세요.<br />
                등록 시 사용자가 구매하기 쉽도록 명확히 작성해주세요.
              </p>
              <button class="add-btn" @click="actions.openSessionModal()">
                세션 / 콘텐츠 추가 +
              </button>
            </div>

            <div v-if="state.clubSessions.length > 0" class="show-sessions">
              <ul>
                <draggable
                  v-model="state.clubSessions"
                  item-key="clubSessions"
                  handle=".handle"
                  :disabled="!state.isAdmin && state.isStartedSale"
                  @change="actions.sortClubSessions(state.clubSessions)"
                >
                  <template #item="{ element, index }">
                    <li>
                      <session-item-new
                        :session="element"
                        :number="index + 1"
                        :club="state.club"
                        :disabled="!state.isAdmin && state.isStartedSale"
                        @deleteSession="
                          (resourceId) => actions.deleteSession(resourceId)
                        "
                        @updating="actions.updateSaveStatus()"
                        @updateSession="
                          actions.updateSession(element.resourceId)
                        "
                      ></session-item-new>
                    </li>
                  </template>
                </draggable>
              </ul>

              <button
                class="add-btn sub-title-s1"
                :disabled="!state.isAdmin && state.isStartedSale"
                @click="actions.openSessionModal()"
              >
                콘텐츠 추가 +
              </button>

              <div v-if="state.isAdmin" class="admin-session__btn">
                <button
                  class="add-btn sub-title-s1"
                  @click="actions.openContentSessionModal()"
                >
                  (admin)콘텐츠 세션 추가 +
                </button>
                <button
                  class="add-btn sub-title-s1"
                  style="margin-top: 10px"
                  @click="actions.openMeetingSessionModal()"
                >
                  (admin)라이브 세션 추가 +
                </button>
              </div>
            </div>
          </div>

          <div class="ticket-section">
            <tooltip
              placement="right"
              :auto-hide="false"
              :is-show="state.isStartedSale"
              :distance="20"
            >
              <template #button>
                <h1>Ticket</h1>
              </template>
              <template #tooltip>
                <span class="sub-text-s3 text-gray-second">
                  이미 판매된 콘텐츠의 티켓은 수정할 수 없습니다. 필요 시 우측
                  하단 고객센터로 문의주세요.
                </span>
              </template>
            </tooltip>

            <!--	Ticket Empty UI  -->
            <div v-if="state.club.allPlans.length === 0" class="empty-card">
              <p class="text-default sub-title-s2">
                생성된 티켓이 없습니다. 티켓을 추가하여<br />
                구매자한테 다양한 선택지를 제공해주세요!
              </p>
              <button class="add-btn" @click="actions.openPlanModal()">
                티켓 추가 +
              </button>
            </div>

            <div v-if="state.club.allPlans.length > 0">
              <ul class="ticket-list-wrapper">
                <li
                  v-for="plan in state.club.allPlans"
                  :key="`plan-${plan.resourceId}`"
                  class="ticket-item"
                >
                  <plan-list-item :plan="plan"></plan-list-item>
                </li>
                <li class="ticket-item empty">
                  <div class="ticket-card">
                    <div class="content">
                      <ticket-icon
                        width="48"
                        height="48"
                        fill-color="#ffffff"
                      ></ticket-icon>
                      <p class="text-default sub-text-s2">
                        티켓을 추가해 보세요!<br />
                        (판매가 시작된 이후에는 추가할 수 없습니다.)
                      </p>
                    </div>
                    <div class="price-section"></div>
                  </div>
                </li>
              </ul>

              <button
                class="add-plan-btn sub-title-s1 bg-gray-020"
                :disabled="state.isStartedSale"
                @click="actions.openPlanModal()"
              >
                티켓 추가 +
              </button>
            </div>
          </div>

          <div class="faq-section">
            <h1 class="title">FAQ</h1>
            <p class="description sub-text-s2 text-gray-second">
              프리미엄 콘텐츠 및 티켓에 관한 FAQ를 작성해주세요.
            </p>

            <hr class="hr-second" />

            <ul>
              <li
                v-for="faq in state.fixedFaqs"
                :key="`faq-item-${faq.resourceId}`"
              >
                <faq-item
                  :faq="faq"
                  :club-resource-id="state.club.resourceId"
                ></faq-item>
              </li>
              <draggable
                v-model="state.clubFaqs"
                item-key="clubsFaq"
                handle=".handle"
                @change="actions.sortFaq(state.clubFaqs)"
              >
                <template #item="{ element }">
                  <li>
                    <faq-item
                      :faq="element"
                      :club-resource-id="state.club.resourceId"
                      :is-edit-status="state.faqEditStatus"
                      @updating="actions.updateSaveStatus()"
                      @openFaqEditForm="actions.openFaqForm(faq)"
                      @editStatusUpdate="
                        (value) => actions.faqEditStatus(value)
                      "
                      @updateFaqs="actions.updateClubFaqs()"
                    ></faq-item>
                  </li>
                </template>
              </draggable>
            </ul>

            <div v-if="state.showFaqForm">
              <faq-form
                :faq="state.editingFaq"
                :club-resource-id="state.club.resourceId"
                @updating="actions.updateSaveStatus()"
                @cancelFaqWriting="actions.cancelFaqWriting()"
                @updateFaqs="actions.updateClubFaqs()"
              ></faq-form>
            </div>

            <button
              v-if="!state.showFaqForm && !state.faqEditStatus"
              class="add-btn sub-title-s1 text-default"
              @click="actions.openFaqForm()"
            >
              FAQ 추가 +
            </button>
          </div>
        </div>
      </div>
    </div>

    <teleport to="#teleport">
      <session-content-modal
        v-if="state.showSessionContentModal"
        :club="state.club"
        @hideModal="actions.closeSessionContentModal()"
        @creatingSession="actions.updateSaveStatus()"
        @saveComplete="actions.contentCreateComplete()"
      ></session-content-modal>

      <session-live-modal
        v-if="state.showSessionLiveModal"
        :club="state.club"
        @hideModal="actions.closeSessionLiveModal()"
        @saveComplete="actions.liveCreateComplete()"
      ></session-live-modal>

      <plan-modal
        v-if="state.showPlanModal"
        :plan="state.editingPlan"
        :club="state.club"
        @hideModal="actions.closePlanModal()"
        @openPlanListModal="actions.openPlanListModal()"
        @saveComplete="actions.planCreateComplete()"
      ></plan-modal>

      <plan-list-modal
        v-if="state.showPlanListModal"
        :club="state.club"
        :plans="state.club.allPlans"
        @hideModal="actions.closePlanListModal()"
        @openPlanModal="actions.openPlanModal()"
        @openPlanEditModal="(plan) => actions.openPlanModal(plan)"
        @deleteComplete="
          (planResourceId) => actions.setSelectedPlan(planResourceId)
        "
      ></plan-list-modal>

      <warning-modal
        v-if="state.showClubDeleteModal"
        warning-title="저장된 내용을 삭제하시겠습니까?"
        warning-text="삭제 후 복구할 수 없습니다."
        confirm-text="삭제"
        @hideModal="actions.closeClubDeleteModal()"
        @confirm="actions.deleteClub()"
      ></warning-modal>

      <warning-modal
        v-if="state.showLeaveModal"
        warning-title="페이지를 나가시겠습니까?"
        warning-text="작성 중인 정보는 모두 자동 저장됩니다."
        confirm-text="나가기"
        cancel-text="머무르기"
        @hideModal="actions.closeLeaveModal()"
        @confirm="actions.leaveCmsPage()"
      ></warning-modal>

      <register-modal
        v-if="state.showRegisterModal"
        :club="state.club"
        :register-type="state.registerSelectedStatus"
        :start-at="state.startAt"
        :club-keywords="state.clubKeywords"
        @hideModal="actions.closeRegisterModal()"
        @setSelectedPublicStatus="
          (value) => actions.setSelectedPublicStatus(value)
        "
        @setStartAt="(startAt) => actions.setStartAt(startAt)"
        @setSaleWithOpen="actions.setSaleWithOpen()"
        @addClubKeyword="(keyword) => actions.addClubKeyword(keyword)"
        @removeClubKeyword="(keyword) => actions.removeClubKeyword(keyword)"
        @openSalesModal="actions.openSalesModal()"
      ></register-modal>

      <sales-modal
        v-if="state.showSalesModal"
        :sales-start-at="state.startAt"
        :keywords="state.clubKeywords"
        :sale-start-with-open="state.saleStartWithOpen"
        :club="state.club"
        @hideModal="actions.closeShareModal()"
        @goToClubShow="(tabName) => actions.goToClubShow(tabName)"
      ></sales-modal>
    </teleport>
  </div>
</template>

<script>
import {
  computed,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  reactive,
  ref,
} from "vue";
import _ from "lodash";
import { useStore } from "vuex";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import PageLoading from "../../../components/console/loadings/PageLoading";
import Avatar from "../../../components/console/avatars/Avatar";
import helper from "@/helper";
import CmsEditor from "./CmsEditor/CmsEditor";
import SessionContentModal from "./SessionContentModal/SessionContentModal";
import SessionLiveModal from "./SessionLiveModal/SessionLiveModal";
import swal from "@/helper/swal";
import FaqItem from "./FaqItem/FaqItem";
import PlanModal from "./PlanModal/PlanModal";
import PlanListModal from "./PlanListModal/PlanListModal";
import draggable from "vuedraggable";
import "./style.css";
import CameraButton from "./CameraButton/CameraButton";
import SessionItemNew from "./SessionItemNew/SessionItemNew";
import InlineTextarea from "./InlineTextarea/InlineTextarea";
import ArrowIcon from "../../../components/console/icons/ArrowIcon";
import ButtonAccordion from "../../../components/console/buttons/ButtonAccordion";
import PlanDropdownItem from "./PlanDropdownItem/PlanDropdownItem";
import { planListPrice, planPrice, planDiscount } from "@/helper/plan";
import SelectedPlan from "./SelectedPlan/SelectedPlan";
import PlanListItem from "./PlanListItem/PlanListItem";
import TicketIcon from "../../../components/console/icons/TicketIcon";
import FaqForm from "./FaqForm/FaqForm";
import WarningModal from "../../../components/console/modals/WarningModal";
import RegisterModal from "./RegisterModal/RegisterModal";
import moment from "moment-timezone";
import SalesModal from "./SalesModal/SalesModal";
import HelpIcon from "../../../components/console/icons/HelpIcon";
import Tooltip from "../../../components/console/tooltip/Tooltip";
import BigcLogo from "../../../components/common/BigcLogo";
import FileStackService from "@/services/FileStackService";
import ButtonText from "@/components/console/buttons/ButtonText.vue";
import DeleteIcon from "@/components/console/icons/DeleteIcon.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

// url 로 진입 시 뒤로가기 하면 스튜디오를 나가버림, 추후 고도화 필요.
let fromUrlNone = false;

export default {
  name: "ClubEdit",
  components: {
    ButtonBasic,
    DeleteIcon,
    ButtonText,
    BigcLogo,
    HelpIcon,
    SalesModal,
    Tooltip,
    RegisterModal,
    WarningModal,
    FaqForm,
    TicketIcon,
    PlanListItem,
    SelectedPlan,
    PlanDropdownItem,
    ButtonAccordion,
    ArrowIcon,
    InlineTextarea,
    SessionItemNew,
    CameraButton,
    PlanListModal,
    PlanModal,
    FaqItem,
    SessionLiveModal,
    SessionContentModal,
    CmsEditor,
    Avatar,
    PageLoading,
    draggable,
  },
  beforeRouteEnter(to, from) {
    if (!from.name) {
      fromUrlNone = true;
    }
  },
  setup() {
    const clubEditWrapper = ref();
    const interestTagsCard = ref();
    const sessionWrapper = ref();
    const interestTagToggleBtn = ref();
    const planListAccordion = ref();

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const clubResourceId = route.params.clubResourceId;
    const { proxy } = getCurrentInstance();

    const state = reactive({
      isAdmin: computed(() => {
        return (
          store.getters["auth/user"].isAdmin ||
          store.getters["auth/user"].isStaff
        );
      }),
      pageLoading: true,
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      updating: false,
      delay: 2000,
      prevFeaturedImage: computed(() => {
        return state.club.featuredImage === proxy.$const.blankImage
          ? ""
          : state.club.featuredImage;
      }),
      club: computed(() => {
        return store.getters["newClubs/newClub"];
      }),
      clubMeta: computed(() => {
        return store.getters["newClubs/newClubMeta"];
      }),
      clubForm: {
        title: "",
        description: "",
        featuredImageFile: "",
        tags: computed(() => {
          let result = state.selectedInterestTags.reduce((sumArr, current) => {
            if (current.name) {
              sumArr.push(current.name);
              return sumArr;
            }
          }, []);
          return result;
        }),
        attachments: [],
      },
      fixedFaqs: computed(() => {
        return store.getters["newClubs/newClubFaqs"].filter((faq) => {
          return faq.isFixed;
        });
      }),
      placeholderTitle: computed(() => {
        return state.club.theme?.holderTitle
          ? state.club.theme.holderTitle.replaceAll(
              "{{name}}",
              helper.displayUserName(state.user)
            )
          : "";
      }),
      placeholderDescription: computed(() => {
        return state.club.theme?.holderDescription
          ? state.club.theme.holderDescription.replaceAll(
              "{{name}}",
              helper.displayUserName(state.user)
            )
          : "";
      }),
      clubFaqs: [],
      selectedInterestTags: [],
      clubSessions: [],
      clubKeywords: [],
      selectedPlan: null,
      selectedPlanListPrice: computed(() => {
        return planListPrice(state.selectedPlan);
      }),
      selectedPlanPrice: computed(() => {
        return planPrice(state.selectedPlan);
      }),
      selectedPlanDiscount: computed(() => {
        return planDiscount(state.selectedPlan);
      }),
      showFaqForm: false,
      editingFaq: null,
      editingPlan: null,
      registerSelectedStatus: "current",
      showInterestTags: false,
      selectedInterestTag: {},
      showPlansList: false,
      showSessionContentModal: false,
      showRegisterModal: false,
      showSalesModal: false,
      showPlanModal: false,
      showSessionLiveModal: false,
      showPlanListModal: false,
      showLeaveModal: false,
      confirmLeave: false,
      isLiveSession: computed(() => {
        return state.club.contentType === "meeting";
      }),
      faqEditStatus: false,
      updateStatus: computed(() => {
        if (state.updating) {
          return "저장 중 ....";
        } else {
          return "저장 완료";
        }
      }),
      showClubDeleteModal: false,
      isStartedSale: computed(() => {
        return state.club.salesCount > 0;
      }),
      startAt: "",
      saleStartWithOpen: false,
    });

    onBeforeMount(async () => {
      await store
        .dispatch("newClubs/getClub", { clubResourceId: clubResourceId })
        .then(() => {
          state.clubForm.title = state.club.title;
          state.clubForm.description = helper.nl2br(state.club.description);
          state.club.attachments.forEach((attachment) => {
            state.clubForm.attachments.push(attachment.resourceId);
          });

          state.clubSessions = [
            ...state.clubSessions,
            ...store.getters["newClubs/newClubSessions"],
          ];

          state.clubFaqs = [
            ...state.clubFaqs,
            ...store.getters["newClubs/newClubFaqs"].filter((faq) => {
              return !faq.isFixed;
            }),
          ];

          state.clubKeywords = [...state.clubKeywords, ...state.club.keywords];

          if (state.club.tags?.length > 0) {
            state.club.tags.forEach((item) => {
              const interestTag = state.clubMeta.interestTags.find(
                (interestTag) => {
                  return interestTag.id === item.id;
                }
              );

              state.selectedInterestTags.push(interestTag);
            });
          }

          state.selectedPlan = state.club.allPlans[0];

          if (
            state.club.contentType === "contents" &&
            state.club.sessions.length === 0
          ) {
            state.showSessionContentModal = true;
          }

          if (
            state.club.contentType === "meeting" &&
            state.club.sessions.length === 0
          ) {
            state.showSessionLiveModal = true;
          }

          state.pageLoading = false;
        });

      if (state.clubSessions.length === 0) {
        sessionWrapper.value.scrollIntoView({ behavior: "smooth" });
      }
    });

    onMounted(() => {
      const callback = async (e) => {
        if (
          state.showInterestTags &&
          clubEditWrapper.value.contains(e.target) &&
          !interestTagsCard.value.contains(e.target) &&
          !interestTagToggleBtn.value.contains(e.target)
        ) {
          toggleInterestTags();
        }
      };
      setTimeout(() => {
        clubEditWrapper.value.addEventListener("click", callback);
      }, 100);
    });

    onBeforeRouteLeave(() => {
      if (!state.confirmLeave) {
        state.showLeaveModal = true;
        return false;
      }
    });

    const putClub = _.debounce(
      (data) => {
        if (data.title) {
          state.clubForm.title = data.title;
        }
        if (data.featuredImgFile) {
          state.clubForm.featuredImgFile = data.featuredImgFile;
        } else {
          delete state.clubForm.featuredImgFile;
        }
        if (data.description) {
          state.clubForm.description = data.description;
        }

        store
          .dispatch("newClubs/putClub", {
            clubResourceId: clubResourceId,
            data: state.clubForm,
          })
          .then(() => {
            finishUpdateStatus();
          });
      },
      state.delay,
      { leading: true }
    );

    const startUpdateStatus = () => {
      state.updating = true;
    };
    const finishUpdateStatus = () => {
      state.updating = false;
    };

    const toggleInterestTags = () => {
      state.showInterestTags = !state.showInterestTags;
    };

    const goToBack = () => {
      if (!state.confirmLeave) {
        state.showLeaveModal = true;
        return;
      }

      if (fromUrlNone) {
        router.push({ name: "console.clubs" });

        return;
      }

      if (!fromUrlNone) {
        router.go(-1);
        return;
      }
    };

    const actions = {
      updateClubTitle: (data) => {
        state.clubForm.title = data.title;
        startUpdateStatus();
        state.delay = 2000;
        putClub(data);
      },
      openFileStack: () => {
        let filestack = new FileStackService();
        filestack.options.transformations.crop.aspectRatio = 1 / 1;
        filestack.options.onFileUploadFinished = (fileMetaData) => {
          startUpdateStatus();
          state.delay = 10;
          putClub({ featuredImgFile: fileMetaData.url });
        };

        filestack.open(filestack.options);
      },
      setInterestTag: (interestTag) => {
        let interestTagIndex = state.selectedInterestTags.findIndex((item) => {
          return item.id === interestTag.id;
        });
        if (interestTagIndex < 0) {
          state.selectedInterestTags.splice(0, 1);
          state.selectedInterestTags.push(interestTag);
        }

        startUpdateStatus();
        state.delay = 10;
        putClub({ tags: state.clubForm.tags });
        toggleInterestTags();
      },
      togglePlansSelectBox: () => {
        state.showPlansList = !state.showPlansList;
      },
      setSelectedPlan: (planResourceId) => {
        if (state.club.allPlans.length === 0) {
          state.selectedPlan = null;
          return;
        }

        let selectedPlan = state.club.allPlans.find((item) => {
          return item.resourceId === planResourceId;
        });

        if (!selectedPlan) {
          state.selectedPlan = state.club.allPlans[0];
        } else {
          state.selectedPlan = selectedPlan;
          planListAccordion.value.toggleAccordion();
        }
      },
      updateClubDescription: (form) => {
        state.clubForm.description = form.content;
        state.clubForm.attachments = form.attachments;

        startUpdateStatus();
        state.delay = 2000;
        putClub({ description: form.content });
      },
      openSessionModal: () => {
        if (state.isLiveSession) {
          state.showSessionLiveModal = true;
        } else {
          state.showSessionContentModal = true;
        }
      },
      openContentSessionModal: () => {
        state.showSessionContentModal = true;
      },
      openMeetingSessionModal: () => {
        state.showSessionLiveModal = true;
      },
      closeSessionContentModal: () => {
        state.showSessionContentModal = false;
      },
      closeSessionLiveModal: () => {
        state.showSessionLiveModal = false;
      },
      contentCreateComplete: () => {
        const newSession = store.getters["newClubs/newClubSessions"].filter(
          (session) => {
            return (
              state.clubSessions.findIndex((item) => {
                return item.resourceId === session.resourceId;
              }) === -1
            );
          }
        );
        state.clubSessions = [...state.clubSessions, ...newSession];
        swal.successToast(`${state.club.title} 의 VOD가 생성되었습니다.`);
        state.showSessionContentModal = false;
        sessionWrapper.value.scrollIntoView({ behavior: "smooth" });
        finishUpdateStatus();
      },
      liveCreateComplete: () => {
        const newSession = store.getters["newClubs/newClubSessions"].filter(
          (session) => {
            return (
              state.clubSessions.findIndex((item) => {
                return item.resourceId === session.resourceId;
              }) === -1
            );
          }
        );
        state.clubSessions = [...state.clubSessions, ...newSession];
        swal.successToast(`${state.club.title} 의 LIVE가 생성되었습니다.`);
        state.showSessionLiveModal = false;

        sessionWrapper.value.scrollIntoView({ behavior: "smooth" });
      },
      updateSaveStatus: () => {
        startUpdateStatus();
      },
      openPlanModal: (plan) => {
        if (!plan) {
          state.editingPlan = null;
        } else {
          state.editingPlan = plan;
        }
        state.showPlanListModal = false;
        state.showPlanModal = true;
      },
      closePlanModal: () => {
        state.showPlanModal = false;
      },
      openPlanListModal: () => {
        state.showPlanModal = false;
        state.showPlanListModal = true;
      },
      planCreateComplete: () => {
        state.selectedPlan = state.club.allPlans[0];
        state.showPlanModal = false;
        setTimeout(() => {
          state.showPlanListModal = true;
        }, 200);
      },
      closePlanListModal: () => {
        state.showPlanListModal = false;
      },
      deleteSession: (resourceId) => {
        const sessionIndex = state.clubSessions.findIndex((session) => {
          return session.resourceId === resourceId;
        });

        state.clubSessions.splice(sessionIndex, 1);

        finishUpdateStatus();
      },
      updateSession: (resourceId) => {
        const updatedSession = state.club.sessions.find((session) => {
          return session.resourceId === resourceId;
        });

        const updatedSessionIndex = state.club.sessions.findIndex((session) => {
          return session.resourceId === resourceId;
        });

        state.clubSessions[updatedSessionIndex] = updatedSession;
        finishUpdateStatus();
      },
      sortClubSessions: (sessions) => {
        startUpdateStatus();
        let sessionResourceIds = [];

        sessions.forEach((session) => {
          sessionResourceIds.push(session.resourceId);
        });

        store
          .dispatch("newClubs/sortSessions", {
            clubResourceId: state.club.resourceId,
            data: {
              sessionResourceIds: sessionResourceIds,
            },
          })
          .then(() => {
            state.club.sessions.forEach((session, index) => {
              state.clubSessions[index] = session;
            });
            finishUpdateStatus();
          });
      },
      openFaqForm: (faq) => {
        if (faq) {
          state.editingFaq = faq;
        } else {
          state.editingFaq = null;
        }
        state.showFaqForm = true;
      },
      cancelFaqWriting: () => {
        state.showFaqForm = false;
      },
      updateClubFaqs: () => {
        state.clubFaqs = [
          ...store.getters["newClubs/newClubFaqs"].filter((faq) => {
            return !faq.isFixed;
          }),
        ];
        state.showFaqForm = false;
        finishUpdateStatus();
      },
      faqEditStatus: (value) => {
        state.faqEditStatus = value;
      },
      deleteFaqComplete: () => {
        finishUpdateStatus();
      },
      sortFaq: (faqList) => {
        let fixedFaqResourceIds = [];
        let faqResourceIds = [];

        state.fixedFaqs.forEach((faq) => {
          fixedFaqResourceIds.push(faq.resourceId);
        });

        faqList.forEach((faq) => {
          faqResourceIds.push(faq.resourceId);
        });
        store
          .dispatch("newClubs/putClubFaqSort", {
            clubResourceId: state.club.resourceId,
            data: {
              faqResourceIds: [...fixedFaqResourceIds, ...faqResourceIds],
            },
          })
          .then(() => {
            swal.successToast("순서가 변경되었습니다.");
          });
      },
      openDeleteClubModal: () => {
        state.showClubDeleteModal = true;
      },
      closeClubDeleteModal: () => {
        state.showClubDeleteModal = false;
      },
      openRegisterModal: () => {
        state.showRegisterModal = true;
      },
      closeRegisterModal: () => {
        state.showRegisterModal = false;
      },
      openSalesModal: () => {
        state.showRegisterModal = false;
        state.showSalesModal = true;
      },
      setSelectedPublicStatus: (value) => {
        state.registerSelectedStatus = value;
      },
      setStartAt: (startAt) => {
        state.startAt = moment(startAt);
      },
      setSaleWithOpen: () => {
        state.saleStartWithOpen = true;
      },
      addClubKeyword: (keyword) => {
        state.clubKeywords.push(keyword);
      },
      deleteClub: () => {
        store
          .dispatch("clubs/deleteClub", {
            clubResourceId: state.club.resourceId,
          })
          .then(() => {
            state.confirmLeave = true;
            swal.deleteCompleteToast();
            router.push({
              name: "console.clubs",
              params: { isTemporary: true },
            });
          });
      },
      removeClubKeyword: (keyword) => {
        const keywordIndex = state.clubKeywords.findIndex((item) => {
          return item.index === keyword.index;
        });

        state.clubKeywords.splice(keywordIndex, 1);
      },
      closeShareModal: () => {
        state.confirmLeave = true;
        state.showSalesModal = false;
      },
      closeLeaveModal: () => {
        state.showLeaveModal = false;
      },
      leaveCmsPage: () => {
        state.confirmLeave = true;
        goToBack();
      },
      goToClubShow: (tabName) => {
        state.confirmLeave = true;
        router.push({
          name: "console.clubs.show",
          params: { clubResourceId: state.club.resourceId },
          query: { tab: tabName },
        });
      },
      goToSampleGuide: () => {
        if (state.club.theme.sampleLink) {
          helper.goToExternalUrl(state.club.theme.sampleLink);
        }
      },
      goToCmsGuide: () => {
        if (state.club.theme.guideLink) {
          helper.goToExternalUrl(state.club.theme.guideLink);
        }
      },
    };

    return {
      state,
      actions,
      helper,
      clubEditWrapper,
      interestTagsCard,
      sessionWrapper,
      interestTagToggleBtn,
      toggleInterestTags,
      planListAccordion,
      goToBack,
    };
  },
};
</script>

<style scoped src="./style.css"></style>
