<template>
  <div class="dark-mode">
    <div class="session-card">
      <!--  handle 클래스는 sorting 할때 터치 되는 element  -->
      <div class="handle drag-handler">
        <img class="col" src="/assets/images/svg/ic_handler.svg" />
      </div>

      <div class="session-info">
        <div
          class="session-featured__img"
          :class="{ empty: !state.isEmptyFeaturedImage }"
          :style="`background-image: url(${state.sessionResource.featuredImage});`"
          @click="actions.openFileStack()"
        >
          <div class="upload-btn">
            <camera-button></camera-button>
          </div>
        </div>
        <div class="text-info">
          <badge
            v-if="!!club.theme"
            :color="state.sessionType.color"
            :text="state.sessionType.text"
          ></badge>

          <div class="title-wrapper">
            <inline-input
              :disabled="disabled"
              placeholder="세션 제목을 입력하세요."
              :text-styles="{
                fontSize: '20px',
                color: '#FFFFFF',
                height: '24px',
              }"
              :default-focusing="!disabled"
              :default-value="state.sessionResourceTitle"
              @enterEvent="actions.focusSessionContents()"
              @updateData="(value) => actions.updateSessionResourceTitle(value)"
              @saveData="actions.updateSession()"
            ></inline-input>
          </div>

          <inline-textarea
            ref="inlineTextArea"
            placeholder="세션 내용을 입력하세요."
            :disabled="disabled"
            :text-styles="{
              fontSize: '14px',
              color: '#9EA0A6',
              lineHeight: 1.4,
              minHeight: '52px',
            }"
            :default-value="state.sessionResourceDescription"
            @updateData="
              (value) => actions.updateSessionResourceDescription(value)
            "
            @saveData="actions.updateSession()"
          ></inline-textarea>
        </div>

        <div
          v-if="state.sessionType.text === 'LIVE'"
          class="live-time"
          @click="actions.openLiveModal()"
        >
          <span>{{ state.liveScheduledAt }}</span>
        </div>
      </div>

      <button
        class="delete-btn"
        :disabled="disabled"
        @click="actions.openDeleteModal()"
      >
        <delete-icon
          :fill-color="disabled ? '#5A5B5F' : '#ffffff'"
        ></delete-icon>
      </button>
    </div>

    <teleport to="#teleport">
      <session-live-modal
        v-if="state.showLiveModal"
        :club="club"
        :meeting="session.meeting"
        @hideModal="actions.closeSessionLiveModal()"
        @saveComplete="actions.liveUpdateComplete()"
      ></session-live-modal>

      <warning-modal
        v-if="state.showSessionDeleteModal"
        warning-title="콘텐츠를 삭제하시겠습니까?"
        warning-text="삭제 후 복구는 불가능합니다."
        confirm-text="삭제"
        @hideModal="actions.closeSessionDeleteModal()"
        @confirm="actions.deleteSession()"
      ></warning-modal>

      <warning-modal
        v-if="state.showSessionFeaturedImageDeleteModal"
        warning-title="해당 이미지를 삭제하시겠습니까?"
        warning-text="삭제 후 복구는 불가능합니다."
        confirm-text="삭제"
        @hideModal="actions.closeFeaturedImageDeleteModal()"
        @confirm="actions.deleteFeaturedImage()"
      ></warning-modal>
    </teleport>
  </div>
</template>

<script>
import {
  computed,
  getCurrentInstance,
  onBeforeMount,
  reactive,
  watch,
  ref,
} from "vue";
import WarningModal from "../../../../components/console/modals/WarningModal";
import swal from "@/helper/swal";
import { useStore } from "vuex";
import FileStackService from "../../../../services/FileStackService";
import moment from "moment-timezone";
import SessionLiveModal from "../SessionLiveModal/SessionLiveModal";
import CameraButton from "../CameraButton/CameraButton";
import InlineInput from "../InlineInput/InlineInput";
import InlineTextarea from "../InlineTextarea/InlineTextarea";
import DeleteIcon from "../../../../components/console/icons/DeleteIcon";
import Badge from "../../../../components/console/badges/Badge.vue";
import ApiService from "@/api";

export default {
  name: "SessionItemNew",
  components: {
    Badge,
    DeleteIcon,
    InlineTextarea,
    InlineInput,
    CameraButton,
    SessionLiveModal,
    WarningModal,
  },
  props: {
    club: {
      type: Object,
      required: true,
    },
    session: {
      type: Object,
      required: false,
    },
    number: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["deleteSession", "updateSession", "updating"],
  setup(props, { emit }) {
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const inlineTextArea = ref();

    const state = reactive({
      sessions: computed(() => {
        return store.getters["newClubs/newClubSessions"];
      }),
      sessionType: computed(() => {
        if (!props.club.theme) {
          return {
            text: "",
            color: "",
          };
        }
        if (props.club.theme.typeLabel === "VOD") {
          return proxy.$const.sessionTypes.vod;
        }
        if (props.club.theme.typeLabel === "Live") {
          // TODO 임시 빅피쳐 라이브 세션에 VOD 콘텐츠 세션 등록 대응
          if (props.session.content) {
            return proxy.$const.sessionTypes.vod;
          }

          return proxy.$const.sessionTypes.live;
        }

        return proxy.$const.sessionTypes.content;
      }),
      // sessionTypeLabel: computed(() => {
      //   return props.club.theme.typeLabel;
      // }),
      sessionResource: computed(() => {
        return props.session.content
          ? props.session.content
          : props.session.meeting;
      }),
      isEmptyFeaturedImage: computed(() => {
        return state.sessionResource.featuredImage === proxy.$const.blankImage;
      }),
      showSessionDeleteModal: false,
      titleEditMode: false,
      sessionResourceTitle: "",
      sessionResourceDescription: "",
      featuredImageFile: null,
      showSessionFeaturedImageDeleteModal: false,
      liveScheduledAt: computed(() => {
        if (props.session.meeting) {
          return `${moment(props.session.meeting.scheduledAt)
            .locale("ko")
            .format("YYYY.MM.DD(ddd) HH:mm")}-${moment(
            props.session.meeting.scheduledFinishAt
          )
            .locale("ko")
            .format("HH:mm")}`;
        }
        return "";
      }),
      showLiveModal: false,
    });

    onBeforeMount(() => {
      setSessionResource();
    });

    const setSessionResource = () => {
      state.sessionResourceTitle = state.sessionResource.title;
      state.sessionResourceDescription = state.sessionResource.description
        ? state.sessionResource.description
        : "";
    };

    watch(
      () => props.session,
      (newSession) => {
        if (newSession.content) {
          state.sessionResourceTitle = newSession.content.title;
          state.sessionResourceDescription = newSession.content.description;
        }
        if (newSession.meeting) {
          state.sessionResourceTitle = newSession.meeting.title;
          state.sessionResourceDescription = newSession.meeting.description;
        }
      }
    );

    const actions = {
      openDeleteModal: () => {
        state.showSessionDeleteModal = true;
      },
      closeSessionDeleteModal: () => {
        state.showSessionDeleteModal = false;
      },
      deleteSession: async () => {
        emit("updating");

        if (props.session.meeting) {
          await store
            .dispatch("newClubs/deleteSessionMeeting", {
              clubResourceId: props.club.resourceId,
              meetingResourceId: state.sessionResource.resourceId,
            })
            .then(() => {
              swal.successToast("세션을 삭제했습니다.");
              emit("deleteSession", props.session.resourceId);
            });
        } else {
          await store
            .dispatch("newClubs/deleteSessionContent", {
              clubResourceId: props.club.resourceId,
              contentResourceId: state.sessionResource.resourceId,
            })
            .then(() => {
              swal.successToast("세션을 삭제했습니다.");

              emit("deleteSession", props.session.resourceId);
            });
        }

        state.showSessionDeleteModal = false;
      },
      updateSessionResourceTitle: (value) => {
        state.sessionResourceTitle = value;
      },
      updateSessionResourceDescription: (value) => {
        state.sessionResourceDescription = value;
      },
      updateSession: () => {
        emit("updating");
        if (!state.sessionResourceTitle) {
          state.sessionResourceTitle = `Session ${props.number}`;
        }

        const payload = {
          title: state.sessionResourceTitle,
          description: state.sessionResourceDescription,
        };

        if (props.session.meeting) {
          store
            .dispatch("newClubs/putClubSessionMeeting", {
              clubResourceId: props.club.resourceId,
              meetingResourceId: state.sessionResource.resourceId,
              data: payload,
            })
            .then(() => {
              emit("updateSession");
            });
        } else {
          store
            .dispatch("newClubs/putClubSessionContent", {
              clubResourceId: props.club.resourceId,
              contentResourceId: state.sessionResource.resourceId,
              data: payload,
            })
            .then(() => {
              emit("updateSession");
            });
        }
      },
      openFeaturedImgDeleteModal: () => {
        state.showSessionFeaturedImageDeleteModal = true;
      },
      closeFeaturedImageDeleteModal: () => {
        state.showSessionFeaturedImageDeleteModal = false;
      },
      deleteFeaturedImage: () => {
        emit("updating");
        // update API 에 parameter 에 featuredImage 를 안보내면 삭제됨.
        const payload = {
          title: state.sessionResourceTitle,
          description: state.sessionResourceDescription,
          featuredImage: "delete",
        };

        if (props.session.meeting) {
          store
            .dispatch("newClubs/putClubSessionMeeting", {
              clubResourceId: props.club.resourceId,
              meetingResourceId: state.sessionResource.resourceId,
              data: payload,
            })
            .then(() => {
              emit("updateSession");
              swal.successToast("삭제되었습니다.");
            });
        } else {
          store
            .dispatch("newClubs/putClubSessionContent", {
              clubResourceId: props.club.resourceId,
              contentResourceId: state.sessionResource.resourceId,
              data: payload,
            })
            .then(() => {
              emit("updateSession");
              swal.successToast("삭제되었습니다.");
            });
        }
      },
      openFileStack: () => {
        if (!props.disabled) {
          const fileStack = new FileStackService();
          // 이미지 비율 세팅
          fileStack.options.transformations.crop.aspectRatio = 16 / 9;

          fileStack.options.onFileUploadFinished = async (fileMetaData) => {
            emit("updating");
            let imageUrl;
            const formData = new FormData();
            formData.append("file", fileMetaData.url);
            await ApiService.postAttachment(formData).then((res) => {
              if (res.data.success) {
                imageUrl = res.data.data.url;
              }
            });

            const payload = {
              title: state.sessionResourceTitle,
              description: state.sessionResourceDescription,
              featuredImage: imageUrl,
            };

            if (props.session.meeting) {
              store
                .dispatch("newClubs/putClubSessionMeeting", {
                  clubResourceId: props.club.resourceId,
                  meetingResourceId: state.sessionResource.resourceId,
                  data: payload,
                })
                .then(() => {
                  emit("updateSession");
                });
            } else {
              store
                .dispatch("newClubs/putClubSessionContent", {
                  clubResourceId: props.club.resourceId,
                  contentResourceId: state.sessionResource.resourceId,
                  data: payload,
                })
                .then(() => {
                  emit("updateSession");
                });
            }
          };

          fileStack.open(fileStack.options);
        }
      },
      openLiveModal: () => {
        if (!props.disabled) {
          state.showLiveModal = true;
        }
      },
      closeSessionLiveModal: () => {
        state.showLiveModal = false;
      },
      liveUpdateComplete: () => {
        emit("updateSession");
        state.showLiveModal = false;
        swal.successToast("라이브 시간이 변경되었습니다.");
      },
      focusSessionContents: () => {
        inlineTextArea.value.actions.toggleEditMode();
      },
    };

    return { state, actions, inlineTextArea };
  },
};
</script>

<style scoped src="./style.css"></style>
