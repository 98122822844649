<template>
  <div class="ticket-card">
    <div class="ticket-description-section">
      <div v-if="!state.soldOut">
        <span class="text-red-50 e-title-2" v-if="plan.isEarlyBird">
          EARLY BIRD
        </span>
        <span class="text-gray-second e-title-2" v-if="!plan.isEarlyBird">
          TICKET
        </span>
      </div>
      <div v-if="state.soldOut">
        <span class="text-red-50 e-title-2"> SOLDOUT </span>
      </div>

      <div class="title-wrapper">
        <h3>{{ plan.title }}</h3>
      </div>
      <hr class="hr-second" />
      <ul class="desc-list-wrapper">
        <li v-for="index in 3" v-bind:key="`plan-description-${index}`">
          <div class="description-item" v-if="!!plan.descriptions[index - 1]">
            <check-icon fill-color="#ffffff"></check-icon>
            <p class="txt text-default">
              {{ plan.descriptions[index - 1] }}
            </p>
          </div>
          <div
            class="description-item-none"
            v-if="!plan.descriptions[index - 1]"
          ></div>
        </li>
      </ul>
    </div>
    <div class="price-section">
      <div class="meta-info" v-if="!state.hiddenListPrice">
        <div class="list-price">
          <span class="text sub-text-s3 text-gray-second">정상가</span>
          <span class="value text-gray-second">{{ state.listPrice }}</span>
        </div>
        <text-divider height="48px" margin="0 16px 0 19px"></text-divider>
        <div class="price">
          <span class="text-default">혜택가</span>
          <h4 class="text-red-50">{{ state.discount }}</h4>
          <span class="text-default">{{ state.price }}</span>
        </div>
      </div>

      <div v-if="state.hiddenListPrice" class="meta-info">
        <span class="text-gray-second sub-text-s3">판매가</span>
        <h2>{{ state.price }}</h2>
      </div>

      <div class="meta-info" v-if="state.soldOut">
        <h3 class="text-default">판매가 종료 되었습니다.</h3>
      </div>
    </div>
  </div>
</template>

<script>
import CheckIcon from "../../../../components/console/icons/CheckIcon";
import { computed, reactive } from "vue";
import {
  planPrice,
  planDiscount,
  planListPrice,
  monthlyPrice,
  planSoldOut,
} from "@/helper/plan";
import TextDivider from "../../../../components/console/dividers/TextDivider";

export default {
  name: "PlanListItem",
  components: { TextDivider, CheckIcon },
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // 할부 개월 수
    const paymentMonth = 3;

    const state = reactive({
      price: computed(() => {
        return planPrice(props.plan);
      }),
      listPrice: computed(() => {
        return planListPrice(props.plan);
      }),
      discount: computed(() => {
        return planDiscount(props.plan);
      }),
      monthlyPrice: computed(() => {
        return monthlyPrice(props.plan, paymentMonth);
      }),
      hiddenListPrice: computed(() => {
        return state.price === state.listPrice || !state.listPrice;
      }),
      soldOut: computed(() => {
        return planSoldOut(props.plan);
      }),
    });

    return { state };
  },
};
</script>

<style src="./style.css" scoped></style>
