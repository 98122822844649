<template>
  <base-modal-side :is-dimmed="true" @closeModal="actions.closeModal()">
    <template #modalHeader>
      <h3 class="text-default">콘텐츠 등록</h3>
      <p class="text-gray-second sub-text-s3">
        판매하고 싶은 콘텐츠를 등록해주세요.
      </p>
    </template>

    <template #modalBody>
      <div class="content-tabs">
        <button-tab
          v-if="!state.isAcceptVideo"
          text="파일 업로드"
          :active="state.activeUploadFileTab"
          @action="actions.activeUploadFileTab()"
        ></button-tab>
        <button-tab
          v-if="!state.disableUploadUrl"
          text="URL로 등록"
          :active="state.activeUploadUrlTab"
          @action="actions.activeUploadUrlTab()"
        ></button-tab>
      </div>

      <!--	파일 업로드 영역  -->
      <div v-show="state.activeUploadFileTab && !state.isAcceptVideo">
        <!--        TODO ANDREW 파일 업로드 프로그레스 테스트 -->
        <!--        <input-->
        <!--          ref="fileSelector"-->
        <!--          type="file"-->
        <!--          @change="actions.selectFile($event)"-->
        <!--          v-bind:style="{ display: 'none' }"-->
        <!--          v-bind:accept="state.mimeTypeString"-->
        <!--        />-->

        <div
          class="file-upload-card"
          @click="actions.openFileStackForContentFile()"
        >
          <i class="fa-solid fa-arrow-up-from-bracket text-default"></i>
          <div class="info">
            <h4 class="title">파일 업로드</h4>
            <p class="sub-text-s3 text-gray-second">
              * 유료로 판매할 콘텐츠 파일을 등록해주세요.<br />
              * 업로드 가능한 파일 : {{ state.mimeTypeString }}
            </p>
            <div
              v-if="!state.uploadedFile"
              class="empty bg-gray-005 sub-text-s3 text-gray-third"
            >
              등록된 파일이 없습니다.
            </div>
          </div>
        </div>

        <div v-if="state.uploadedFile" class="uploaded-file">
          <memo-icon></memo-icon>
          <span class="name">{{ state.uploadedFile.name }}</span>
          <button class="delete-btn" @click.stop="actions.deleteUploadedFile()">
            <delete-icon></delete-icon>
          </button>
        </div>
      </div>

      <!--	url 업로드 영역  -->
      <div v-show="state.activeUploadUrlTab && !state.disableUploadUrl">
        <input-basic
          label="URL"
          type="url"
          placeholder="주소를 입력해주세요."
          :required="true"
          :default-value="state.url"
          :error-message="state.urlErrorMessage"
          @updateData="(value) => actions.getMetaFromUrl(value)"
        ></input-basic>

        <div v-if="!state.successGetMetaFromUrl" class="empty-meta">
          <div class="inner">
            <p class="text-gray-third sub-text-s3">
              유튜브나 vimeo 영상의 URL을 입력해주세요.<br />
              URL을 입력하면 영상 정보를 가져옵니다.
            </p>
          </div>
        </div>

        <!--	수집해온 콘텐츠 미리보기  -->
        <div v-if="state.successGetMetaFromUrl" class="prev-area">
          <div class="thumbnail">
            <label>
              <span class="sub-text-s2">썸네일</span>
              <span class="text-purple-50">*</span>
            </label>
            <div class="thumb-content">
              <div ref="sessionFeaturedImage" class="session-featured"></div>
              <div class="buttons">
                <button-basic
                  class="upload"
                  shape="round"
                  bg-color="#E6EAEF"
                  padding="8px"
                  @action="actions.openFileStack()"
                >
                  <template #icon>
                    <upload-icon></upload-icon>
                  </template>
                </button-basic>

                <button-basic
                  class="delete"
                  shape="round"
                  bg-color="#E6EAEF"
                  padding="8px"
                  @action="actions.deleteContentImage()"
                >
                  <template #icon>
                    <delete-icon></delete-icon>
                  </template>
                </button-basic>
              </div>
            </div>
          </div>

          <input-basic
            class="title"
            label="제목(세션명)"
            placeholder="제목을 입력해주세요."
            :required="true"
            :default-value="state.contentsForm.title"
            :error-message="state.titleErrorMessage"
            @updateData="(value) => actions.updateContentTitle(value)"
          ></input-basic>

          <input-text-box
            v-model="state.contentsForm.description"
            class="description"
            label="소개"
            placeholder="콘텐츠에 대한 설명을 간단하게 작성해주세요."
            :required="true"
            :border-none="false"
          ></input-text-box>
        </div>
      </div>
    </template>

    <template #modalFooter>
      <div class="btn-wrapper">
        <button-basic
          class="cancel"
          text="취소"
          color="#0d0d0d"
          bg-color="#E6EAEF"
          @action="actions.closeModal()"
        ></button-basic>

        <button-basic
          class="save"
          text="등록"
          :disabled="!state.activeRegisterBtn"
          @action="actions.registerContentSession()"
        ></button-basic>
      </div>
    </template>
  </base-modal-side>
</template>

<script>
import BaseModalSide from "../../../../components/common/BaseModalSide.vue";
import { computed, getCurrentInstance, onMounted, reactive, ref } from "vue";
import ButtonTab from "../../../../components/console/buttons/ButtonTab";
import InputBasic from "../../../../components/console/inputs/InputBasic";
import { useStore } from "vuex";
import ApiService from "@/api";
import helper from "@/helper";
import moment from "moment-timezone";
import InputTextBox from "../../../../components/console/inputs/InputTextBox";
import FileStackService from "../../../../services/FileStackService";
import MemoIcon from "../../../../components/console/icons/MemoIcon";
import DeleteIcon from "../../../../components/console/icons/DeleteIcon";
import UploadIcon from "../../../../components/console/icons/UploadIcon";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default {
  name: "SessionContentModal",
  components: {
    ButtonBasic,
    UploadIcon,
    DeleteIcon,
    MemoIcon,
    InputTextBox,
    InputBasic,
    ButtonTab,
    BaseModalSide,
  },
  props: {
    club: {
      type: Object,
      required: true,
    },
  },
  emits: ["hideModal", "saveComplete"],
  setup(props, { emit }) {
    const store = useStore();
    const sessionFeaturedImage = ref();
    const fileSelector = ref();

    const { proxy } = getCurrentInstance();

    const state = reactive({
      activeUploadFileTab: true,
      activeUploadUrlTab: false,
      successGetMetaFromUrl: false,
      url: "",
      contentsForm: {
        type: 0,
        channelCode: "",
        identifier: "",
        url: "",
        title: "",
        description: "",
        text: "",
        file: "",
        publishedAt: "",
        isPaid: true,
        attachmentIds: [],
      },
      uploadedFile: null,
      fileStackUploadedData: null,
      mimeTypes: computed(() => {
        // MAC 에서 audio/x-m4a 타입이 있으면 select 창에서 .m4a 파일이 active 되지만, 해당파일을 선택시 accept 할수 없다. accept는 .mp4 파일의 mimetype 이 있어야 한다.
        if (props.club.theme?.mimeTypes.includes("audio/x-m4a")) {
          return [...props.club.theme.mimeTypes, "audio/mp4"];
        } else {
          return props.club.theme?.mimeTypes;
        }
      }),
      mimeTypeOptions: [
        { value: "image/png", label: "png" },
        { value: "image/jpeg", label: "jpg,jpeg" },
        { value: "image/gif", label: "gif" },
        { value: "image/svg+xml", label: "svg" },
        { value: "image/webp", label: "webp" },
        { value: "video/x-msvideo", label: "avi" },
        { value: "video/mpeg", label: "mp4" },
        { value: "video/webm", label: "webm" },
        { value: "audio/x-aac", label: "aac" },
        { value: "audio/midi", label: "midi" },
        { value: "audio/ogg", label: "ogg" },
        { value: "audio/wav", label: "wav" },
        { value: "audio/mpeg", label: "mp3" },
        { value: "audio/mp4", label: "m4a" },
        { value: "text/plain", label: "txt" },
        { value: "application/pdf", label: "pdf" },
      ],
      mimeTypeString: computed(() => {
        let mimeTypeArr = state.mimeTypes.reduce((result, current) => {
          let mimetype = state.mimeTypeOptions.find((item) => {
            return item.value === current;
          });
          if (mimetype) {
            result.push(mimetype.label);
          }
          return result;
        }, []);
        return mimeTypeArr.join(", ");
      }),
      activeRegisterBtn: computed(() => {
        return (
          (state.activeUploadFileTab && !!state.uploadedFile) ||
          (state.activeUploadUrlTab && state.successGetMetaFromUrl)
        );
      }),
      saveProcessing: false,
      urlErrorMessage: "",
      titleErrorMessage: computed(() => {
        return !state.contentsForm.title ? "재목은 필수입니다." : "";
      }),
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      config: computed(() => {
        return {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          onUploadProgress: (event) => {
            store.dispatch("file/updateUploadingFileLoaded", {
              fileName: state.uploadedFile.name,
              loaded: event.loaded,
              total: event.total,
            });
          },
        };
      }),
      isAcceptVideo: computed(() => {
        return (
          state.mimeTypes.findIndex((item) => {
            return item.includes("video");
          }) > -1
        );
      }),
      disableUploadUrl: computed(() => {
        return (
          state.mimeTypes.findIndex((mimeType) => {
            return (
              mimeType.includes("image") ||
              mimeType.includes("text/plain") ||
              mimeType.includes("application/pdf")
            );
          }) > -1
        );
      }),
    });

    onMounted(() => {
      if (state.isAcceptVideo) {
        state.activeUploadFileTab = false;
        state.activeUploadUrlTab = true;
      }
    });

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      activeUploadFileTab: () => {
        state.activeUploadUrlTab = false;
        state.activeUploadFileTab = true;
      },
      activeUploadUrlTab: () => {
        state.activeUploadFileTab = false;
        state.activeUploadUrlTab = true;
      },
      getMetaFromUrl: (url) => {
        state.url = url;
        ApiService.getContentMetaFromUrl({ url: state.url })
          .then((res) => {
            if (res.data.success) {
              state.urlErrorMessage = "";
              state.contentsForm = { ...state.contentsForm, ...res.data.data };
              state.successGetMetaFromUrl = true;
              setTimeout(() => {
                sessionFeaturedImage.value.style.backgroundImage = `url(${res.data.data.featuredImage})`;
              }, 10);
            } else {
              state.urlErrorMessage = "유효하지않은 URL 입니다.";
              state.successGetMetaFromUrl = false;
            }
          })
          .catch((e) => {
            console.log(e);
            state.urlErrorMessage = "유효하지않은 URL 입니다.";
            state.successGetMetaFromUrl = false;
          });
      },
      registerContentSession: () => {
        emit("creatingSession");
        if (state.activeRegisterBtn && !state.saveProcessing) {
          state.saveProcessing = true;
          // let payload = {};

          if (state.activeUploadFileTab) {
            state.contentsForm.title = `${helper.displayUserName(
              state.user
            )}의 ${props.club.typeLabel} 세션`;
            state.contentsForm.publishedAt = moment(new Date()).toJSON();
            state.contentsForm.description = ".";

            // payload = helper.makeFormData(state.contentsForm, null, "post");
          }

          if (state.activeUploadUrlTab) {
            delete state.contentsForm.file;
          }

          store
            .dispatch("newClubs/postClubSessionContent", {
              clubResourceId: props.club.resourceId,
              data: state.contentsForm,
            })
            .then(() => {
              emit("saveComplete");
              state.saveProcessing = false;
            });
        }
      },
      openFileUrl: (attachmentUrl) => {
        window.open(attachmentUrl, "_blank");
      },
      updateContentTitle: (value) => {
        state.contentsForm.title = value;
      },
      openFileStack: () => {
        const fileStack = new FileStackService();
        fileStack.options.transformations.crop.aspectRatio = 16 / 9;
        fileStack.options.onFileUploadFinished = (fileMetaData) => {
          sessionFeaturedImage.value.style.backgroundImage = `url(${fileMetaData.url})`;
          state.contentsForm.featuredImage = fileMetaData.url;
        };

        fileStack.open(fileStack.options);
      },

      openFileStackForContentFile: () => {
        const fileStack = new FileStackService();
        fileStack.options.transformations.crop = false;
        fileStack.options.transformations.circle = false;
        fileStack.options.transformations.rotate = false;
        fileStack.options.accept = state.mimeTypes;
        fileStack.options.onFileUploadFinished = (fileMetaData) => {
          state.contentsForm.file = fileMetaData.url;
          state.contentsForm.filename = fileMetaData.filename;
          state.uploadedFile = fileMetaData.originalFile;
        };

        fileStack.open(fileStack.options);
      },

      deleteContentImage: () => {
        state.contentsForm.file = "";
        sessionFeaturedImage.value.style.backgroundImage = `url(${proxy.$const.blankImage})`;
        state.contentsForm.featuredImage = null;
      },
      deleteUploadedFile: () => {
        state.contentsForm.file = null;
        state.contentsForm.filename = null;
        state.uploadedFile = null;
      },
      // TODO ANDREW TEST 중
      // openFileSelector: () => {
      //   fileSelector.value.click();
      // },
      // selectFile: (event) => {
      //   state.uploadedFile = event.target.files.item(0);
      // },
      // test: async () => {
      //   await store.dispatch("file/setUploadingFile", state.uploadedFile);
      //
      //   let formData = new FormData();
      //   formData.set("file", state.uploadedFile);
      //
      //   emit("saveComplete");
      //   state.saveProcessing = false;
      //
      //   await ApiService.fileUploadTest(formData, state.config).then((res) => {
      //     if (res.data.success) {
      //       console.log("success");
      //     }
      //   });
      // },
    };

    return {
      state,
      actions,
      sessionFeaturedImage,
      fileSelector,
    };
  },
};
</script>

<style scoped src="./style.css"></style>
