<template>
  <div class="inline-textarea-wrapper">
    <div
      v-if="!state.isEditMode"
      ref="previewText"
      class="prev-area"
      :class="{ active: !disabled }"
    >
      <p
        class="prev-text"
        :style="state.boxStyle"
        @click="!disabled ? actions.toggleEditMode() : ''"
        v-html="defaultValue ? defaultValue : placeholder"
      ></p>
      <span class="edit-icon">
        <edit-icon></edit-icon>
      </span>
    </div>
    <textarea
      v-if="state.isEditMode"
      ref="textareaElement"
      v-model="state.text"
      type="text"
      class="textarea-input"
      :placeholder="placeholder"
      :maxlength="maxLength"
      :style="state.boxStyle"
      :disabled="disabled"
      @change="actions.updateData($event)"
      @keypress="actions.keyPressEvent($event)"
      @blur="actions.toggleEditMode()"
    >
    </textarea>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from "vue";
import EditIcon from "../../../../components/console/icons/EditIcon";

export default {
  name: "InlineTextarea",
  components: { EditIcon },
  props: {
    defaultValue: {
      type: String,
      default: "",
    },
    textStyles: {
      type: Object,
      default: () => {
        return {
          fontSize: "24px",
          color: "#E5E5EB",
          lineHeight: "34px",
          fontWeight: 700,
          height: "81px",
          minHeight: "81px",
        };
      },
    },
    maxLength: {
      type: Number,
      required: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    enableEnter: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["saveData", "updateData"],
  setup(props, { emit }) {
    const textareaElement = ref();
    const previewText = ref();

    const state = reactive({
      text: props.defaultValue,
      isEditMode: !props.defaultValue,
      boxStyle: props.textStyles,
    });

    watch(
      () => props.defaultValue,
      (value) => {
        state.text = value;
        state.boxStyle.height = "fit-content";
      }
    );

    onMounted(() => {
      let defaultBoxStyle = {
        fontSize: "24px",
        color: "#E5E5EB",
        lineHeight: "34px",
        fontWeight: 700,
        height: "81px",
        minHeight: "81px",
      };

      let height;
      if (state.isEditMode && !props.defaultValue) {
        height = textareaElement.value.clientHeight;
      } else {
        height = previewText.value.clientHeight;
      }

      state.boxStyle = {
        ...defaultBoxStyle,
        ...props.textStyles,
        ...{ height: `${height}px` },
      };
    });

    const actions = {
      toggleEditMode: () => {
        if (state.isEditMode && !props.defaultValue) {
          state.isEditMode = true;
        } else {
          if (state.isEditMode) {
            state.boxStyle = {
              ...state.boxStyle,
              ...{ height: "auto" },
            };
          } else {
            let height = previewText.value.clientHeight;
            state.boxStyle = {
              ...state.boxStyle,
              ...{ height: `${height}px` },
            };
            setTimeout(() => {
              textareaElement.value.focus();
            }, 50);
          }
          state.isEditMode = !state.isEditMode;
        }
        emit("saveData");
      },
      updateData: (e) => {
        state.text = e.target.value;
        emit("updateData", e.target.value);
      },
      keyPressEvent: (e) => {
        if (!props.enableEnter && e.keyCode === 13) {
          e.preventDefault();
          return false;
        }
      },
    };

    return { state, actions, textareaElement, previewText };
  },
};
</script>

<style src="./style.css" scoped></style>
