<template>
  <img
    src="/assets/images/brand/logo/BIGCSTUDIO_Logotype_Black_2x_Comp.png"
    alt="빅크 로고이미지"
    :style="state.logoStyle"
  />
</template>

<script>
import { reactive } from "vue";

export default {
  name: "BigcLogo",
  props: {
    width: {
      type: String,
      default: "126",
    },
  },
  setup(props) {
    const state = reactive({
      logoStyle: {
        width: props.width,
        height: "auto",
      },
    });

    return { state };
  },
};
</script>
