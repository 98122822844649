<template>
  <div>
    <div class="question-wrapper">
      <span class="sub-title-s1">Q.</span>
      <input
        ref="questionTitle"
        v-model="state.faqForm.question"
        type="text"
        placeholder="FAQ에 등록하실 질문을 작성해주세요."
      />
    </div>

    <faq-editor
      :content="state.faqForm.answer"
      @updateContent="(form) => actions.updateAnswer(form)"
    ></faq-editor>

    <div class="form-buttons">
      <span class="cancel-btn-wrapper">
        <button
          class="sub-title-s1 bg-gray-020"
          @click="actions.cancelWriting()"
        >
          취소
        </button>
      </span>

      <span class="save-btn-wrapper">
        <button
          class="sub-title-s1"
          :disabled="state.saveDisabled"
          @click="actions.saveFaq()"
        >
          <span>{{ state.isEditMode ? "수정" : "완료" }}</span>
          <check-icon
            v-if="!state.isEditMode"
            class="check"
            :fill-color="state.saveDisabled ? '#5A5B5F' : '#ffffff'"
          ></check-icon>
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from "vue";
import FaqEditor from "../FaqEditor/FaqEditor";
import { useStore } from "vuex";
import CheckIcon from "../../../../components/console/icons/CheckIcon";

export default {
  name: "FaqForm",
  components: { CheckIcon, FaqEditor },
  props: {
    faq: {
      type: Object,
      required: false,
    },
    clubResourceId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const questionTitle = ref();

    const store = useStore();

    const state = reactive({
      faqForm: {
        question: "",
        answer: "",
      },
      isEditMode: !!props.faq,
      saveDisabled: computed(() => {
        return !state.faqForm.question || !state.faqForm.answer;
      }),
    });

    onMounted(() => {
      if (props.faq) {
        state.faqForm.question = props.faq.localizations[0].question;
        state.faqForm.answer = props.faq.localizations[0].answerHtml;
      }

      questionTitle.value.focus();
    });

    const actions = {
      updateAnswer: (form) => {
        state.faqForm.answer = form.content;
      },
      cancelWriting: () => {
        emit("cancelFaqWriting");
      },
      saveFaq: () => {
        emit("updating");
        let payload = {
          localizations: [
            {
              lang: "ko",
              question: state.faqForm.question,
              answer: state.faqForm.answer,
            },
          ],
          model: "clubs",
          resourceId: props.clubResourceId,
        };
        if (state.isEditMode) {
          payload.localizations[0]["resourceId"] =
            props.faq.localizations[0].resourceId;
          store
            .dispatch("newClubs/putClubFaq", {
              clubResourceId: props.clubResourceId,
              resourceId: props.faq.resourceId,
              data: payload,
            })
            .then(() => {
              emit("updateFaqs");
            });
        } else {
          store
            .dispatch("newClubs/postClubFaq", {
              clubResourceId: props.clubResourceId,
              data: payload,
            })
            .then(() => {
              emit("updateFaqs");
            });
        }
      },
    };

    return { state, actions, questionTitle };
  },
};
</script>

<style src="./style.css" scoped></style>
